import {makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import Colors from "../constants/Colors";
import {Box, Button, Divider, Stack} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CodeIcon from '@mui/icons-material/Code';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import NavigationPaths from "../constants/NavigationPaths";
import Dimensions from "../constants/Dimensions";
import {isEmailValid} from "../login/AuthValidation";
import Constants from "../constants/Constants";
import ReCAPTCHA from "react-google-recaptcha";
import {addAwaitingUser} from "./AwaitingUser";
import {AppDataContext} from "../AppDataContext";
import {HorizontalContentDivider} from "../ui/ContentDivider";
import {useScrollPosition} from "../utils/ScrollPosition";
import {getNewGradientState, initialGradientDegreeState} from "./GradientState";

// https://mui.com/components/use-media-query/
// How to use queries: https://stackoverflow.com/questions/45847090/media-queries-in-mui-components
// Breakpoints: https://mui.com/customization/breakpoints/

export default function Home() {

    const styles = useStyles()

    const appData = useContext(AppDataContext)

    const firebaseApp = appData.firebaseApp
    if (firebaseApp != null) {
        firebaseApp.analytics().logEvent('screen_view', {
            firebase_screen: "Home",
            firebase_screen_class: "Home"
        });
    }

    return (
        <div className={styles.root}>

            <WelcomeSection/>

            <PreviewSection/>

            <FeatureSection/>

            <InfoSection/>

        </div>
    )
}

function WelcomeSection() {

    const styles = welcomeStyles()

    // Degree of gradient backgrounds is updated every 2.5 second
    const gradientDegreeChangeInterval = 2500

    // Smoothness of gradient that might also affect performance
    const gradientFramesPerSecond = 60

    // Gradient background is updated every 50 milliseconds
    const gradientUpdateInterval = 1000 / gradientFramesPerSecond

    // Displayed gradient alpha each time gradientDegreeChangeInterval is reached
    const maxGradientAlpha = 0.25

    const scrollPosition = useScrollPosition()
    // const maxScroll = document.body.offsetHeight - window.innerHeight;

    // console.log("Current scroll position: " + scrollPosition)
    // console.log("Max scroll position: " + maxScroll)

    const [gradientDegreeState, setGradientDegreeState] = useState(initialGradientDegreeState)

    // TODO : Disable for mobile?
    // gradientDegreeState.gradientTimerActive = window.screen.width >= 600;

    // const [gradientTimerActive, setGradientTimerActive] = useState(true);

    //     useState(GradientDegree.DEG_90)
    // const [previousGradientDegree, setPreviousGradientDegree] = useState(getRandomGradientDegree)

    // const welcomeBackground = () => {
    //
    //     const defaultDeg = 135
    //     const defaultBackground = `linear-gradient(${defaultDeg}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.10407913165266103) 100%)`;
    //
    //     if (maxScroll <= 0) {
    //         return defaultBackground
    //     }
    //
    //     const MAX_DEGREE = 180 // 90
    //
    //     // Visible until 800
    //     // Full 1371
    //     // 50% scroll from top -> 45 degree change
    //     // 100% scroll -> 90 degree change
    //
    //     const scrollPercentage = (scrollPosition / maxScroll) * 100
    //     // console.log("Scroll percentage " + scrollPercentage)
    //
    //     const addDegree = (scrollPercentage / 100) * MAX_DEGREE
    //     // console.log("Adding degree " + addDegree)
    //
    //     const newDegree = defaultDeg + addDegree
    //     // console.log("new degree " + newDegree)
    //
    //     // Path 135 (default - down right corner) - 180 (down left corner)
    //     return `linear-gradient(${newDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.1) 100%)`
    //     // return defaultBackground
    // }

    // /**
    //  * Welcome background is updated every time according to specified gradientDegreeChangeInterval interval and degrees changed every 5 seconds.
    //  */
    // const welcomeBackground = () => {
    //
    //     const currentDegree = gradientDegreeState.gradientState.valueOf()
    //     const previousDegree = gradientDegreeState.previousGradientState.valueOf()
    //     const gradientTimerActive = gradientDegreeState.gradientTimerActive
    //     const ms = gradientDegreeState.milliseconds
    //
    //     if (!gradientTimerActive) {
    //         // static background
    //         return `linear-gradient(135deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.1) 100%)`
    //     }
    //
    //     // expanding progress + shrinking progress = 100
    //     // how much left to reach gradientDegreeChangeInterval in percentage
    //     const expandingGradientProgress = (ms / gradientDegreeChangeInterval) * 100
    //     // Invert
    //     const shrinkingGradientProgress = Math.abs(expandingGradientProgress - 100)
    //
    //     // 100% = 0.2 alpha
    //     const expandingGradientAlpha = (expandingGradientProgress / 100) * maxGradientAlpha
    //     const shrinkingGradientAlpha = (shrinkingGradientProgress / 100) * maxGradientAlpha
    //
    //     if (ms % 5000 === 0 || ms === 0) {
    //         // console.log("Millis " + ms)
    //         // console.log("Current degree " + currentDegree)
    //         // console.log("Previous degree " + previousDegree)
    //         // console.log("Expanding gradient alpha " + expandingGradientAlpha)
    //         // console.log("Shrinking gradient height " + shrinkingGradientAlpha)
    //     }
    //
    //     // Gradients are simultaneously becoming more visible or more hidden
    //     // const defaultBackground = `linear-gradient(${currentDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.1) ${expandingGradientHeight}%),
    //     //                             linear-gradient(${previousDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.1) ${shrinkingGradientHeight}%)`;
    //
    //     const defaultBackground = `linear-gradient(${currentDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${expandingGradientAlpha}) 100%),
    //                                 linear-gradient(${previousDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${shrinkingGradientAlpha}) 100%)`;
    //
    //     // const defaultBackground = `linear-gradient(135deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${expandingGradientAlpha}) 100%)`;
    //
    //     return defaultBackground
    // }

    /**
     * Welcome background is updated every time according to specified gradientDegreeChangeInterval interval and degrees changed every 5 seconds.
     */
    const welcomeBackground = () => {

        const currentDegree = gradientDegreeState.gradientState.valueOf()
        const previousDegree = gradientDegreeState.previousGradientState.valueOf()
        // const currentDegree2 = gradientDegreeState.gradientState2.valueOf()
        // const previousDegree2 = gradientDegreeState.previousGradientState2.valueOf()
        const gradientTimerActive = gradientDegreeState.gradientTimerActive
        const ms = gradientDegreeState.milliseconds

        if (!gradientTimerActive) {
            // static background
            return `linear-gradient(135deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.1) 100%)`
        }

        // expanding progress + shrinking progress = 100
        // how much left to reach gradientDegreeChangeInterval in percentage
        const expandingGradientProgress = (ms / gradientDegreeChangeInterval) * 100
        // Invert
        const shrinkingGradientProgress = Math.abs(expandingGradientProgress - 100)
        // const shrinkingGradientProgress = 100 - expandingGradientProgress

        // if (ms % 5000 === 0 || ms === 0) {
        //     console.log("Millis " + ms)
        //     console.log("Current degree " + currentDegree)
        //     console.log("Previous degree " + previousDegree)
        //     console.log("Expanding gradient alpha " + expandingGradientProgress)
        //     console.log("Shrinking gradient height " + shrinkingGradientProgress)
        // }

        // 100% = 0.2 alpha
        const expandingGradientAlpha = (expandingGradientProgress / 100) * maxGradientAlpha
        const shrinkingGradientAlpha = (shrinkingGradientProgress / 100) * maxGradientAlpha
        // const expandingGradientAlpha2 = (expandingGradientProgress / 100) * maxGradientAlpha
        // const shrinkingGradientAlpha2 = (shrinkingGradientProgress / 100) * maxGradientAlpha

        return `linear-gradient(${currentDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${expandingGradientAlpha}) 100%),
                                    linear-gradient(${previousDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${shrinkingGradientAlpha}) 100%)`
        // return `linear-gradient(${currentDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${expandingGradientAlpha}) 100%),
        //                             linear-gradient(${previousDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${shrinkingGradientAlpha}) 100%),
        //                             linear-gradient(${currentDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${expandingGradientAlpha2}) 100%),
        //                             linear-gradient(${previousDegree}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,${shrinkingGradientAlpha2}) 100%)`
    }

    useEffect(() => {
        let interval: any = null;
        if (gradientDegreeState.gradientTimerActive) {
            interval = setInterval(() => {

                if (gradientDegreeState.milliseconds >= gradientDegreeChangeInterval) {

                    // Delay before switching to new state
                    setTimeout(() => {

                        // Get new state
                        setGradientDegreeState(getNewGradientState(gradientDegreeState))

                    }, 500)

                } else {

                    // Update milliseconds
                    setGradientDegreeState(prevState => ({
                        ...prevState,
                        milliseconds: prevState.milliseconds + gradientUpdateInterval
                    }))
                }

            }, gradientUpdateInterval);
        } else { //  && seconds !== 0
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [gradientDegreeState, gradientUpdateInterval]);

    const handleArrowClick = () => {

        const element = document.getElementById('preview_section_id')

        if (element != null) {

            const scrollHeight = element.scrollHeight;

            // console.log("Scroll height " + element.scrollHeight)
            // console.log("Current scroll " + scrollPosition)

            // Prevent scroll up
            if (scrollHeight > scrollPosition) {
                element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
            }
        }
    }

    // const matches = useMediaQuery('(min-width:700px)', { noSsr: true });
    // const matches = useMediaQuery((theme => {theme.breakpoints.up('sm')});

    return (
        <div className={styles.welcomeSection} style={{
            // minHeight: `calc(100vh - (${FOOTER_HEIGHT} + 20px))`,
            // minHeight: minHeight(),
            background: welcomeBackground(),
        }}>

            <div className={styles.welcomeContent}>

                <div className={styles.welcomeTitleSection}>

                    <Typography variant="h1" className={styles.welcomeTitle}>
                        Change the way you work
                    </Typography>

                    <Typography variant="h5" className={styles.welcomeTitleDescription}>
                        Work Tracking is project focused on managing work in organisations through attendance,
                        providing
                        overview of all members, applying rules and your own ideas that support workflow in
                        companies
                        and groups.
                    </Typography>

                </div>

                <Box component="span" sx={{p: 2, borderRadius: '50px'}} className={styles.welcomeCard}>

                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem
                                          style={{background: Colors.CARD_PRIMARY_BORDER_COLOR}}/>}
                        spacing={2}
                    >

                        <img src={'/images/shift_tracking_night_2.webp'} alt={'Tracking in progress'}
                             className={styles.welcomeCardImage}/>

                        <img src={'/images/attendance_night_2.webp'} alt={'Tracking in progress'}
                             className={styles.welcomeCardImage}/>

                    </Stack>
                </Box>

            </div>

            <a className={styles.welcomeArrowSection} onClick={handleArrowClick}>
                <KeyboardArrowDownIcon fontSize={"medium"} className={styles.welcomeArrow}/>
            </a>

        </div>
    )
}

function PreviewSection() {

    const styles = previewStyles()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={styles.previewSection} id={'preview_section_id'}>

            <Box component="span" sx={{p: 2, borderRadius: '50px'}} className={styles.previewCard}>

                <Stack
                    direction="row"
                    spacing={isMobile ? 2 : 5}
                >

                    <div className={styles.previewCardContent}>
                        <p className={styles.previewCardText}>Get attendance of each member in your
                            organisation.</p>
                        <img src={'/images/half_attendance_night.webp'} alt={'AttendancePanel preview'}
                             className={styles.previewCardImage}/>
                    </div>

                    <div className={styles.previewCardContent}>
                        <img src={'/images/half_map_night_2.webp'} alt={'Map of shift'}
                             className={styles.previewCardImage}/>
                        <p className={styles.previewCardText}>See where your employees were during their shift.</p>
                    </div>

                </Stack>
            </Box>

            <Stack
                direction="column"
                divider={<Divider orientation="horizontal" flexItem
                                  style={{background: Colors.CARD_PRIMARY_BORDER_COLOR}}/>}
                spacing={isMobile ? 2 : 5}
            >

                <div className={styles.platformSection}>
                    <Typography variant="h5" className={styles.previewPlatformCardText}>
                        Soon available on
                    </Typography>

                    <Stack
                        direction="row"
                        spacing={5}
                    >
                        <AndroidIcon sx={{fill: 'lightgreen', fontSize: '50px'}}/>

                        <AppleIcon sx={{fill: 'lightgrey', fontSize: '50px'}}/>

                        <CodeIcon sx={{fill: Colors.PRIMARY_TEXT_BLUE, fontSize: '50px'}}/>

                    </Stack>
                </div>

                <Typography className={styles.previewLearnMoreText}>
                    Learn more in <a href={NavigationPaths.ABOUT}
                                     className={styles.previewLearnMoreAbout}>About</a>
                </Typography>

            </Stack>

        </div>
    )
}

function FeatureSection() {

    const styles = featureStyles()

    return (

        <Stack direction="column"
               spacing={0}
               className={styles.featureSection}>

            <HorizontalContentDivider/>

            <div className={styles.featureSectionContent}>

                <div className={styles.featureColumn}>

                    <div className={styles.featureItem}>
                        <h4 className={styles.featureTitle}>Organisations</h4>
                        <p className={styles.featureDescription}>Our software is suitable for most types of companies
                            with different labour specialisation,
                            whether you work as an individual or whole group of people and perform manual or physical
                            work.
                        </p>
                    </div>

                    <div className={styles.featureItem}>
                        <h4 className={styles.featureTitle}>Technologies</h4>
                        <p className={styles.featureDescription}>Everything is built on the newest technologies
                            to continuously provide the best possible experience.
                            We're open to new ideas that would help
                            with
                            managing your own organisation.</p>
                    </div>

                </div>

                <div className={styles.featureColumn}>

                    <div className={styles.featureItem}>
                        <h4 className={styles.featureTitle}>Integration between platforms</h4>
                        <p className={styles.featureDescription}>Access and manage your organisation anywhere.
                            Download our app on Android, iOS or use web to synchronise data between members of your
                            groups.</p>
                    </div>

                    <div className={styles.featureItem}>
                        <h4 className={styles.featureTitle}>Your own ideas</h4>
                        <p className={styles.featureDescription}>We plan on to integrate plugin system with our own and
                            on-demand plugins that can make use of
                            existing organisation hierarchy and simplify your company tasks.</p>
                    </div>

                </div>
            </div>

            <HorizontalContentDivider/>

        </Stack>

    )
}

function InfoSection() {

    const styles = infoStyles()

    const [email, setEmail] = useState("")
    const [emailSubmitted, setEmailSubmitted] = useState(false)
    const [userValid, setUserValid] = useState(false)

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        // Id and value of changed field
        const {id, value} = e.target

        setEmail(value)
    }

    function onRecaptchaChange(value: string | null) {
        // console.log("Captcha value:", value);

        if (value != null) {
            setUserValid(true)
        }
    }

    function onRecaptchaExpired() {
        // console.log("Captcha expired");
        setUserValid(false)
    }

    const handleGetNotifiedClick = () => {

        addAwaitingUser(email, (successful => {
            if (successful) {
                setEmailSubmitted(true)
            }
        }))
    }

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const emailValid = isEmailValid(email)
    const captchaVisible = emailValid && !emailSubmitted
    const buttonText = emailSubmitted ? 'Thank you ✓' : 'Get notified'
    const buttonSpan = (
        <span style={{fontSize: isMobile ? '8px' : Dimensions.REDIRECT_SMALL_TEXT_SIZE}}>{buttonText}</span>
    )

    return (
        <div className={styles.root}>

            <div className={styles.testAppSection}>

                <Typography variant={"h4"} className={styles.testAppTitle}>
                    Be the first to try the app
                </Typography>

                <Typography variant={"h6"} className={styles.testAppDescription}>
                    Provide us with your email and we'll contact you once the app is released.
                </Typography>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                }}>

                    <input type={"email"}
                           placeholder={"Type your email"}
                           autoComplete="current-email"
                           onChange={handleEmailChange}
                           disabled={emailSubmitted}
                           className={styles.testAppInput}/>

                    {!emailValid || !userValid ? null : (

                        <Button variant="contained"
                                onClick={handleGetNotifiedClick}
                                disabled={emailSubmitted}
                                sx={{
                                    height: '40px',
                                    marginLeft: '10px',
                                    borderRadius: 2,
                                    backgroundColor: emailSubmitted ? 'green' : '#1565C0',
                                    color: 'white',
                                    ":hover": {
                                        backgroundColor: Colors.PRIMARY_TEXT_BLUE,
                                    },
                                    "&:disabled": {
                                        backgroundColor: 'green',
                                        color: 'white',
                                    },
                                }}
                                className={styles.getNotifiedButton}
                                color="primary">{buttonSpan}</Button>
                    )}
                </div>

                {/*https://www.npmjs.com/package/react-google-recaptcha*/}
                {!captchaVisible || userValid ? null : (
                    <ReCAPTCHA
                        sitekey={Constants.RECAPTCHA_KEY}
                        onChange={onRecaptchaChange}
                        onExpired={onRecaptchaExpired}
                        theme={"dark"}
                        size="normal"
                        className={styles.captcha}
                        badge={"bottomright"}
                    />
                )}

            </div>

            <div className={styles.moreInfoSection}>

                <Typography variant={"h6"} className={styles.moreInfoText} id={'moreInfoAvailableSoon'}>
                    More info available soon
                </Typography>
            </div>

        </div>
    )
}

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.PRIMARY_BACKGROUND_COLOR,
    },
});

const welcomeStyles = makeStyles(theme => ({
    welcomeSection: {

        display: "flex",
        flexDirection: "column",
        paddingTop: "50px",
        width: '100%',

        // Make sure home is displayed on whole screen
        // minHeight: `calc(100vh - (${FOOTER_HEIGHT} + 70px))`,

        // Make home displayed on whole screen (100% size of screen - navigation height - padding top)
        minHeight: `calc(100vh - (70px + 50px))`,
        position: 'relative',

        // TODO
        [theme.breakpoints.down('sm')]: {
            // fontSize: '80px',
            // width: '480px',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'static',

            justifyContent: "space-between",

            // minHeight: 'auto',
            // paddingTop: 0,

            // position: 'auto',
        },
    },
    welcomeContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: '100%',

        // Make it appear in 30% height from top
        position: "absolute",
        top: '30%',
        transform: 'translateY(-30%)',

        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",

            position: "static",
            top: 'auto',
            transform: 'none',
        },

        // [theme.breakpoints.down('sm')]: {
        //     marginTop: Dimensions.SECTION_PADDING_TOP,
        //     marginBottom: Dimensions.SECTION_PADDING_TOP,
        // },
    },
    welcomeTitleSection: {},
    welcomeTitle: {
        color: Colors.PRIMARY_TEXT_BLUE,
        fontSize: '100px', // fontSize: '6em',
        width: '600px',
        wordBreak: 'break-word',
        margin: 0,
        padding: 0,
        fontWeight: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
        whiteSpace: 'pre-line',
        lineHeight: 1,

        [theme.breakpoints.down('lg')]: {
            fontSize: '80px',
            width: '480px',
        },
        [theme.breakpoints.down('sm')]: {
            // TODO
            fontSize: '40px',
            width: Dimensions.MOBILE_PAGE_WIDTH,
        },

        // TODO : Transparency animation
    },
    welcomeTitleDescription: {
        color: Colors.SECONDARY_TEXT_COLOR,
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE, // fontSize: '1em',
        margin: 0,
        fontWeight: 500,
        padding: '10px',
        width: '500px',

        [theme.breakpoints.down('lg')]: {
            fontSize: Dimensions.SECTION_DESCRIPTION_MD_SIZE,
            width: '400px',
        },
        [theme.breakpoints.down('sm')]: {
            // TODO
            width: Dimensions.MOBILE_PAGE_WIDTH,
            fontSize: '15px',
            // padding: 0,
            // margin: 0,
            marginTop: '10px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },

        // animation: 'fadeIn 1s'
        // TODO : Transparency animation
    },
    // "@keyframes fadeIn": {
    //     "0%": {opacity: 0},
    //     "100%": {opacity: 1},
    // },
    welcomeCard: {
        backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
        padding: '2px',
        borderRadius: '100px',
        border: `1px solid ${Colors.CARD_PRIMARY_BORDER_COLOR}`,

        [theme.breakpoints.down('sm')]: {
            marginTop: "30px",
            marginBottom: '15px',
        },
    },
    welcomeCardImage: {
        height: '700px',

        [theme.breakpoints.down('lg')]: {
            height: '560px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '300px',
        },
    },
    welcomeArrowSection: {
        paddingTop: '25px',
        paddingBottom: '25px',
        display: 'flex',
        justifyContent: 'center',

        // Make arrow always appear at bottom
        marginTop: "auto",

        [theme.breakpoints.down('sm')]: {
            // display: 'none !important',
            paddingTop: '10px',
            paddingBottom: '10px',
        },
    },
    welcomeArrow: {
        alignSelf: 'flex-end',
        bottom: 0,
    },
}));

const PREVIEW_IMAGE_WIDTH = '250px'
const MOBILE_PREVIEW_IMAGE_WIDTH = '150px'

const previewStyles = makeStyles(theme => ({
    previewSection: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: 'center',
        paddingTop: Dimensions.SECTION_PADDING_TOP_DOUBLE,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            paddingTop: Dimensions.SECTION_PADDING_TOP,
        },
    },
    previewCard: {
        backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
        padding: '2px',
        borderRadius: '100px',
        border: `1px solid ${Colors.CARD_PRIMARY_BORDER_COLOR}`,
    },
    previewCardContent: {
        width: PREVIEW_IMAGE_WIDTH,

        [theme.breakpoints.down('sm')]: {
            width: MOBILE_PREVIEW_IMAGE_WIDTH,
        },
    },
    previewCardImage: {
        width: PREVIEW_IMAGE_WIDTH,

        [theme.breakpoints.down('sm')]: {
            width: MOBILE_PREVIEW_IMAGE_WIDTH,
        },
    },
    previewCardText: {
        textAlign: "center",
        width: PREVIEW_IMAGE_WIDTH,
        color: Colors.SECONDARY_TEXT_COLOR,
        fontWeight: 400,
        fontSize: Dimensions.INFO_TITLE_TEXT_SIZE,

        [theme.breakpoints.down('lg')]: {
            fontSize: Dimensions.INFO_TITLE_TEXT_SIZE_MD,
        },
        [theme.breakpoints.down('sm')]: {
            width: MOBILE_PREVIEW_IMAGE_WIDTH,
        },
    },
    platformSection: {
        margin: 0,
        padding: 0,

        [theme.breakpoints.down('sm')]: {
            marginTop: Dimensions.SECTION_PADDING_TOP
        },
    },
    previewPlatformCardText: {
        color: Colors.SECONDARY_TEXT_COLOR,
        textAlign: 'center',
        padding: '10px',

        fontSize: Dimensions.INFO_TITLE_TEXT_SIZE,
        [theme.breakpoints.down('lg')]: {
            fontSize: Dimensions.INFO_TITLE_TEXT_SIZE_MD,
        },
    },
    previewLearnMoreText: {
        textAlign: "center",
        color: Colors.SECONDARY_TEXT_COLOR,
        fontWeight: 400,
        fontSize: Dimensions.INFO_TITLE_TEXT_SIZE,

        [theme.breakpoints.down('lg')]: {
            fontSize: Dimensions.INFO_TITLE_TEXT_SIZE_MD,
        },
        [theme.breakpoints.down('sm')]: {

        },
    },
    previewLearnMoreAbout: {
        textAlign: "center",
        color: Colors.SECONDARY_TEXT_COLOR,
        textDecoration: 'none',
        fontWeight: 600,

        '&:hover': {
            color: Colors.PRIMARY_TEXT_BLUE,
        },
    },
}));

const featureStyles = makeStyles(theme => ({
    featureSection: {
        width: '100%',
        paddingTop: Dimensions.SECTION_PADDING_TOP_DOUBLE,

        [theme.breakpoints.down('sm')]: {
            paddingTop: Dimensions.SECTION_PADDING_TOP,
        },
    },
    featureSectionContent: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "space-evenly",
        width: '100%',

        paddingTop: Dimensions.SECTION_PADDING_TOP_HALF,
        paddingBottom: Dimensions.SECTION_PADDING_TOP_HALF,
        backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    contentDivider: {
        background: Colors.CARD_PRIMARY_BORDER_COLOR,
        // marginLeft: '20%',
        // marginRight: '20%',
        margin: 0,
        padding: 0,
    },
    featureColumn: {
        display: "flex",
        flexDirection: 'column',
    },
    featureItem: {
        width: '500px',
        padding: '25px',

        // flex: 1 1 160px;
        // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items

        [theme.breakpoints.down('sm')]: {
            width: Dimensions.MOBILE_PAGE_WIDTH,

            // paddingLeft: '25px',
            // paddingRight: '25px',
            // paddingTop: '10px',
            // paddingBottom: '10px',
        },
    },
    featureTitle: {
        fontSize: Dimensions.SECTION_TITLE_SIZE,
        color: Colors.PRIMARY_TEXT_COLOR,
        padding: 0,
        margin: 0,

        [theme.breakpoints.down('lg')]: {
            fontSize: Dimensions.SECTION_TITLE_MD_SIZE,
        },
    },
    featureDescription: {
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        paddingTop: '5px',
        paddingBottom: '10px',
        margin: 0,

        color: Colors.SECONDARY_TEXT_COLOR,
        fontWeight: 400,

        [theme.breakpoints.down('lg')]: {
            fontSize: Dimensions.SECTION_DESCRIPTION_MD_SIZE,
        },
    },
}));


const infoStyles = makeStyles(theme => ({
    root: {
        paddingTop: '75px',
        paddingBottom: '25px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        width: '100%',

    },
    testAppSection: {
        textAlign: 'center',
        width: '100%',

        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            width: Dimensions.MOBILE_PAGE_WIDTH,
        },
    },
    testAppTitle: {
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.PRIMARY_TEXT_COLOR,
        padding: 0,
        margin: 0,
        fontWeight: 600,

        // [theme.breakpoints.down('lg')]: {
        //     fontSize: '18px',
        // },
    },
    testAppDescription: {

        fontSize: '18px',
        paddingTop: '5px',
        paddingBottom: '10px',
        margin: 0,

        color: Colors.SECONDARY_TEXT_COLOR,
        fontWeight: 400,

        // [theme.breakpoints.down('lg')]: {
        //     fontSize: '16px',
        // },

        [theme.breakpoints.down('lg')]: {
            fontSize: Dimensions.SECTION_DESCRIPTION_MD_SIZE,
        },
    },
    testAppInput: {
        fontSize: '14px',
        width: '500px',
        height: '40px',
        paddingLeft: '15px',
        paddingRight: '15px',

        borderStyle: 'none',
        // borderStyle: 'solid',
        // borderWidth: '1px',
        borderRadius: '10px',
        // borderColor: Colors.CARD_BORDER_DARK_GRAY,

        // height: '20px',
        backgroundColor: Colors.INPUT_BACKGROUND_DARK_GRAY,
        color: Colors.SECONDARY_TEXT_COLOR,

        '&:focus': {
            outline: 'none',
        },
    },
    captcha: {
        marginTop: '15px',
    },
    getNotifiedButton : {
    },
    moreInfoSection: {
        position: 'relative',
        paddingTop: '50px',
    },
    moreInfoText: {

        padding: '15px',
        fontWeight: 600,
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,

        // Animated underline is defined in App.css

        // Animated underline
        // '&:hover': {
        //     width: '100%',
        //     left: 0,
        //
        //     '&:after': {
        //         background: 'none repeat scroll 0 0 transparent',
        //         bottom: 0,
        //         content: "",
        //         display: 'block',
        //         height: '1px',
        //         left: '50%',
        //         position: 'absolute',
        //         // background: '#fff',
        //         transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
        //         width: 0,
        //     },
        // },
    },
}));

