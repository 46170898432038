import React from "react";
import Dimensions from "../constants/Dimensions";
import {makeStyles, Typography} from "@material-ui/core";
import Colors from "../constants/Colors";
import {Box, Divider, Stack} from "@mui/material";
import {contentDividerStyle, HorizontalContentDivider} from "../ui/ContentDivider";
import {useScrollPosition} from "../utils/ScrollPosition";

export default function About() {

    const styles = useStyles()

    const scrollPosition = useScrollPosition()
    const maxScroll = document.body.offsetHeight - window.innerHeight;

    const featureBackground = () => {

        // Percentage (start 100% - 50%)
        const HEIGHT_FROM = 50
        const HEIGHT_TO = 100

        // const DEGREE_FROM = 45
        // const DEGREE_TO = 135

        // const defaultBackground = `linear-gradient(${defaultDeg}deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.10407913165266103) 100%)`;
        const defaultBackground = `linear-gradient(180deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.1) ${HEIGHT_FROM}%)`;

        if (maxScroll <= 0) {
            return defaultBackground
        }

        // Visible until 800
        // Full 1371
        // 50% scroll from top -> 45 degree change
        // 100% scroll -> 90 degree change

        const scrollPercentage = (scrollPosition / maxScroll) * 100
        // console.log("Scroll percentage: " + scrollPercentage)

        // 100% scroll will make full HEIGHT_TO
        const addHeight = (scrollPercentage / 100) * HEIGHT_TO
        // console.log("Adding degree " + addDegree)

        const newHeight = HEIGHT_FROM + addHeight
        // console.log("new degree " + newDegree)

        // const addDegree = (scrollPercentage / 100) * DEGREE_TO
        // const newDegree = DEGREE_FROM + addDegree
        // console.log("New degree: " + newDegree)

        // Path 135 (default - down right corner) - 180 (down left corner)
        return `linear-gradient(180deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.1) ${newHeight}%)`
        // return defaultBackground
    }

    const CardTopLine = () => {
        return (
            <Divider orientation="horizontal" variant={'middle'} flexItem style={{
                background: Colors.COLOR_ACCENT_BLUE,
                margin: 0,
                padding: 0,
                height: '3px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                marginRight: '1px',
                marginLeft: '1px',
            }}/>
        )
    }

    return (
        <div className={styles.root}>

            <div className={styles.aboutHeaderWrapper}>

                <div className={styles.aboutInfoSectionText}>

                    <h1 className={styles.aboutTitle}>About</h1>

                    <div className={styles.aboutDescriptionSection}>

                        <p className={styles.aboutDescription}>Work Tracking is a software that provides work tool for
                            daily basis.
                            It's features were made following a real demand of creating tracking system, simplifying
                            management of work
                            and providing a clear overview of employees in the company.
                        </p>

                        <Divider orientation="vertical" variant={'middle'} flexItem style={contentDividerStyle}
                                 className={styles.aboutDescriptionDivider}/>

                        <p className={styles.aboutDescription}>
                            Purpose of our software lays in <b style={{fontWeight: 'bolder'}}>simplifying everyday work
                            and creating whole process transparent</b>.
                            Wouldn't it be better to come to work, tap your phone and let everyone know that you came?
                            Our goal is to automate this process.
                        </p>
                    </div>

                    <div className={styles.aboutDescriptionFooter}>

                        <p className={styles.aboutDescriptionFooterText}>
                            As you can imagine this can open up a lot of new opportunities and insights into regular
                            work day.
                            <br/>
                            <br/>

                            <span style={{
                                color: Colors.REDIRECT_SMALL_TEXT_COLOR,
                                fontSize: Dimensions.INFO_TITLE_TEXT_SIZE
                            }}>
                                Read out more details bellow
                            </span>

                        </p>

                    </div>

                </div>

            </div>

            <HorizontalContentDivider/>

            <div className={styles.featureSection} style={{
                background: featureBackground(),
            }}>

                <div className={styles.aboutBoxRow} style={{marginTop: Dimensions.SECTION_PADDING_TOP}}>

                    <Box component="span" sx={aboutBoxStyle} className={styles.aboutBox}>

                        <CardTopLine/>

                        <div className={styles.aboutBoxContent}>

                            <Typography variant={"h4"} className={styles.aboutBoxTitle}>Work Tracking</Typography>

                            <p className={styles.aboutBoxDescription}>
                                Goal of our software is to provide the best possible tool for everyday work and
                                automate whole process of not just creating an attendance that can your employer
                                directly access,
                                but proper work tool that will make your professional life easier.
                            </p>

                        </div>
                    </Box>


                    <Box component="span" sx={aboutBoxStyle} className={`${styles.aboutBox} ${styles.middleAboutBox}`}>

                        <CardTopLine/>

                        <div className={styles.aboutBoxContent}>

                            <Typography variant={"h4"} className={styles.aboutBoxTitle}>Attendance</Typography>

                            {/*<p className={styles.aboutBoxDescription}>*/}
                            {/*    Access your attendance, view your month total or work statistics anywhere.*/}
                            {/*    Attendance consists of shifts in month periods.*/}
                            {/*</p>*/}

                            <p className={styles.aboutBoxDescription}>
                                Your attendance is divided into periods, which consist of shifts that you can
                                easily access. You'll get an overview of total hours worked and other available
                                statistics
                                for given period, that you can view and manage, either as a member or manager of the
                                group.
                            </p>

                        </div>
                    </Box>

                    <Box component="span" sx={aboutBoxStyle} className={styles.aboutBox}>
                        <CardTopLine/>

                        <div className={styles.aboutBoxContent}>

                            <Typography variant={"h4"} className={styles.aboutBoxTitle}>Shifts</Typography>

                            <p className={styles.aboutBoxDescription}>
                                Shifts can be started anywhere or added manually with providing all the useful
                                information
                                for your employer in one place. Include important notes, photos or turn on location and
                                display
                                your routes on the map. Let the smart features manage your shifts and tracking during
                                work.
                            </p>

                        </div>
                    </Box>

                </div>


                <div className={styles.aboutBoxRow} style={{}}>

                    <Box component="span" sx={aboutBoxStyle} className={styles.aboutBox}>

                        <CardTopLine/>

                        <div className={styles.aboutBoxContent}>

                            <Typography variant={"h4"} className={styles.aboutBoxTitle}>Groups</Typography>

                            <p className={styles.aboutBoxDescription}>
                                Join or create groups that are divided into managers and members
                                with an option to assign them a specific privileges or restrictions
                                that take place during validation of an attendance.
                                Multiple groups can be connected to a company and they provide the main structure for
                                the users.
                            </p>

                        </div>

                    </Box>

                    <Box component="span" sx={aboutBoxStyle} className={`${styles.aboutBox} ${styles.middleAboutBox}`}>

                        <CardTopLine/>

                        <div className={styles.aboutBoxContent}>

                            <Typography variant={"h4"} className={styles.aboutBoxTitle}>Tasks</Typography>

                            <p className={styles.aboutBoxDescription}>
                                Keep your tasks in the same place and never forget about them.
                                Assign them to the other members of your group, give them priority according to
                                your needs and get notified about upcoming work that needs to be done.
                            </p>

                        </div>

                    </Box>

                    <Box component="span" sx={aboutBoxStyle} className={styles.aboutBox}>

                        <CardTopLine/>

                        <div className={styles.aboutBoxContent}>

                            <Typography variant={"h4"} className={styles.aboutBoxTitle}>Plugins</Typography>

                            <p className={styles.aboutBoxDescription}>
                                Plugins are specific features that make use of existing organisation hierarchy
                                with goal to simplify your company tasks. They are presented as an optional feature that
                                can be assigned to
                                concrete members of your group.
                            </p>

                            <p className={styles.aboutBoxDescription}
                               style={{
                                   color: Colors.REDIRECT_SMALL_TEXT_COLOR,
                                   fontSize: Dimensions.INFO_TITLE_TEXT_SIZE
                               }}>
                                In development.
                            </p>

                        </div>
                    </Box>

                </div>

                <div className={styles.aboutFooterWrapper}>

                    <Divider orientation="horizontal" variant={'middle'} flexItem style={{
                        background: Colors.CARD_PRIMARY_BORDER_COLOR,
                        margin: 0,
                        padding: 0,
                    }}/>

                    <div className={styles.aboutFooter}>

                        <p className={styles.aboutFooterText}>
                            First release of Work Tracking will be made available on Android and <u
                            style={{textDecorationThickness: '0.5px', textUnderlineOffset: '3px'}}>free for anyone to
                            try and test</u>.
                            The more feedback we receive from you, the faster we can go further and <b
                            style={{fontWeight: 'bolder'}}>create perfect solution for every workplace</b>.
                        </p>
                    </div>

                    <Divider orientation="horizontal" variant={'middle'} flexItem style={{
                        background: Colors.CARD_PRIMARY_BORDER_COLOR,
                        margin: 0,
                        padding: 0,
                    }}/>

                </div>

            </div>


            {/*TasksPanel, vacation, ... */}

        </div>
    )
}

const aboutBoxStyle = ({
    // p: 2,
    // borderRadius: '50px',
    borderRadius: '10px',
    boxShadow: 3,
});

const BOX_SIZE = '250px'
const BOX_HEIGHT = '300px'
const BOX_WIDTH = '400px'
const BOX_MARGIN = '20px'
const MOBILE_BOX_HEIGHT = 'auto'
const MOBILE_BOX_WIDTH = '350px'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: Colors.PRIMARY_BACKGROUND_COLOR,
    },
    aboutHeaderWrapper: {
        width: '100%',
        paddingTop: Dimensions.SECTION_PADDING_TOP,
        paddingBottom: Dimensions.SECTION_PADDING_TOP_HALF,
        backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
    },
    aboutInfoSectionText: {
        width: Dimensions.PAGE_WIDTH,

        marginLeft: 'auto',
        marginRight: 'auto',
        // paddingTop: Dimensions.SECTION_PADDING_TOP,
        // paddingBottom: Dimensions.SECTION_PADDING_TOP,
        // backgroundColor: Colors.CARD_BLACK_DARK_GRAY,

        [theme.breakpoints.down('sm')]: {
            width: Dimensions.MOBILE_PAGE_WIDTH,
        },
    },
    aboutTitle: {
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,
        color: Colors.PRIMARY_TEXT_COLOR,

        [theme.breakpoints.down('sm')]: {
            // fontSize: Dimensions.MOBILE_PAGE_TITLE_TEXT_SIZE,
            margin: 0,
            padding: 0,
        },
    },
    aboutDescriptionSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: '100%',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            // width: Dimensions.MOBILE_PAGE_WIDTH,
        },
    },
    aboutDescription: {
        // marginBottom: "auto",
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.SECONDARY_TEXT_COLOR,
        width: '45%',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            // width: Dimensions.MOBILE_PAGE_WIDTH,
            // fontSize: Dimensions.MOBILE_SECTION_DESCRIPTION_SIZE,
        },
    },
    aboutDescriptionDivider: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    aboutDescriptionFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100%',
        marginTop: Dimensions.SECTION_PADDING_TOP_HALF
    },
    aboutDescriptionFooterText: {
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.SECONDARY_TEXT_COLOR,
        width: '60%',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
            padding: 0,
        },
    },
    featureSection: {
        width: '100%',
        // background: 'linear-gradient(180deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.10407913165266103) 75%)',
        // paddingBottom: Dimensions.SECTION_PADDING_TOP,
    },
    aboutBoxRow: {
        display: "flex",
        flexDirection: "row",
        // justifyContent: 'space-evenly',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '100%',

        // https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
        // position: 'relative',
        // overflow: 'hidden',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    aboutBox: {
        background: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
        width: BOX_WIDTH,
        height: BOX_HEIGHT,
        border: `1px solid ${Colors.CARD_PRIMARY_BORDER_COLOR}`,
        margin: BOX_MARGIN,
        // padding: 0,

        // position: 'relative',
        // overflowY: 'hidden',
        // overflow: 'scroll',

        // Make sure padding is not ignored when element is scrollable
        // https://stackoverflow.com/questions/53768260/css-pseudo-selectors-with-mui
        // '&::after': {
        //     display: 'block',
        //     height: 60,
        //     marginTop: -60
        // },

        // TODO : For unavailable content
        // opacity: 0.5,

        [theme.breakpoints.down('sm')]: {
            width: MOBILE_BOX_WIDTH,
            height: MOBILE_BOX_HEIGHT,
        },
    },
    middleAboutBox: {
        // marginTop: '100px',
        marginBottom: '-100px',

        [theme.breakpoints.down('sm')]: {
            marginBottom: BOX_MARGIN,
        },
    },
    aboutBoxContent: {
        padding: '25px',
        margin: 0,
    },
    aboutBoxTitle: {
        fontSize: Dimensions.SECTION_TITLE_SIZE,
        color: Colors.PRIMARY_TEXT_COLOR,
        margin: 0,
        padding: 0,
    },
    aboutBoxDescription: {
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.SECONDARY_TEXT_COLOR,

        padding: 0,
        margin: 0,
        marginTop: '20px',

        // TODO: Make content scrollable
        // overflow: 'scroll',
        // overflowY: 'hidden',
    },
    aboutFooterWrapper: {
        width: '100%',
        marginTop: Dimensions.SECTION_PADDING_TOP_DOUBLE,
        marginBottom: Dimensions.SECTION_PADDING_TOP,

        // paddingTop: Dimensions.SECTION_PADDING_TOP_HALF,
        // paddingBottom: Dimensions.SECTION_PADDING_TOP_HALF,
        // backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
        // // background: 'linear-gradient(145deg, rgba(2,0,36,0) 10%, rgba(30,136,229,0.10407913165266103) 100%)',

        [theme.breakpoints.down('sm')]: {
            marginTop: Dimensions.SECTION_PADDING_TOP,
            marginBottom: Dimensions.SECTION_PADDING_TOP,
        },
    },
    aboutFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100%',
        textAlign: 'center',

        width: '100%',
        paddingTop: Dimensions.SECTION_PADDING_TOP_HALF,
        paddingBottom: Dimensions.SECTION_PADDING_TOP_HALF,
        backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,

        [theme.breakpoints.down('sm')]: {
            // paddingTop: Dimensions.SECTION_PADDING_TOP_HALF,
            // paddingBottom: Dimensions.SECTION_PADDING_TOP_HALF,
            padding: 0,
        },
    },
    aboutFooterText: {
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.SECONDARY_TEXT_COLOR,
        width: Dimensions.PAGE_WIDTH,

        [theme.breakpoints.down('sm')]: {
            width: Dimensions.MOBILE_PAGE_WIDTH,
            // fontSize: '18px',
        },
    }
}));
