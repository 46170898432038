import React, {useEffect, useRef, useState} from "react";

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        };

        window.addEventListener("scroll", updatePosition);

        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return scrollPosition;
};

// https://stackoverflow.com/a/65637632/12580605
export const useSmoothScrollTo = (id : string) => {
    const ref = useRef(null)
    useEffect(() => {
        const listener = (e : HashChangeEvent)  => {
            // eslint-disable-next-line no-restricted-globals
            if (ref.current && location.hash === id) {

                // @ts-ignore
                ref.current.scrollIntoView({behavior: 'smooth'})
            }
        }
        window.addEventListener('hashchange', listener, true)
        return () => {
            window.removeEventListener('hashchange', listener)
        }
    }, [id])
    return {
        'data-anchor-id': id,
        // 'data-anchor-className'
        ref
    }
}