import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {DialogProps} from "@mui/material/Dialog/Dialog";
import {useState} from "react";
import Colors from "../constants/Colors";
import LoginTextField from "./LoginTextField";
import {LoginConstants} from "./LoginConstants";
import {isInviteCodeValid} from "./AuthValidation";
import {getInviteCode} from "../firebase/firestore/Listeners";
import {dialogPaperProps} from "../ui/MUIStyles";
import {useMediaQuery, useTheme} from "@material-ui/core";

export default function EmployerDialog(props: EmployerDialogProps) {

    const [inviteCode, setInviteCode] = useState('')
    const [inviteCodeBlurred, setInviteCodeBlurred] = useState(false)
    const [dialogSubmitted, setDialogSubmitted] = useState(false)
    const [inviteCodeTaken, setInviteCodeTaken] = useState(false)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.handleCloseDialog()
    }

    const handleInviteCodeChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target
        // console.log("invite code changed with " + value)
        setInviteCode(value)

        if (inviteCodeTaken) {
            setInviteCodeTaken(false)
        }
    }

    const handleInviteCodeBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // console.log("Invite code blurred")
        setInviteCodeBlurred(true)
    }

    const handleContinue = () => {

        setDialogSubmitted(true)

        if (!isInviteCodeValid(inviteCode)) {
            // console.log("Invite code is not valid")
            return
        }

        isInviteCodeAvailable(inviteCode, (available => {

            if (!available) {
                setInviteCodeTaken(true)
                return
            }

            // Return result
            props.handleDialogResult(inviteCode)
        }))
    }

    let invalidInviteCode = false
    if ((inviteCodeBlurred && inviteCode !== '') || dialogSubmitted) {
        invalidInviteCode = !isInviteCodeValid(inviteCode)
    }
    let inviteCodeHelperText = ''
    if (invalidInviteCode) {
        inviteCodeHelperText = LoginConstants.INVALID_INVITE_CODE
    } else if (inviteCodeTaken) {
        invalidInviteCode = true
        inviteCodeHelperText = LoginConstants.TAKEN_INVITE_CODE
    }

    // Separate custom props from DialogProps
    const {handleCloseDialog, handleDialogResult, ...rest} = props

    return (
        <div>
            <Dialog {...rest}
                    onClose={handleClose}
                    sx={{}}
                    PaperProps={dialogPaperProps}
            >
                <DialogTitle
                    sx={{
                        color: Colors.PRIMARY_TEXT_COLOR,
                    }}
                >Set an invite code</DialogTitle>

                <DialogContent>

                    <LoginTextField
                        autoFocus={true}
                        label={'Invite code'}
                        // fullWidth
                        type={'text'}
                        style={{
                            marginTop: '10px',
                            width: isMobile ? '80%' : 'inherit'
                        }}
                        value={inviteCode}
                        onChange={handleInviteCodeChange}
                        error={invalidInviteCode}
                        helperText={inviteCodeHelperText}
                        onBlur={handleInviteCodeBlur}
                    />

                    <DialogContentText
                        sx={{
                            color: Colors.SECONDARY_TEXT_COLOR,
                            marginTop: isMobile ? '10px' : '50px',
                            padding: isMobile ? '10px' : '0px',
                        }}>
                        This invite code is used by employees,
                        when creating new account and joining your organisation.
                        It can be changed later in settings.
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleContinue}>Continue</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function isInviteCodeAvailable(inviteCode: string, callback: (available: boolean) => void) {
    getInviteCode(inviteCode, (inviteCodeModel => {
        callback(inviteCodeModel === null)
    }))
}

interface EmployerDialogProps extends DialogProps {

    handleCloseDialog: () => void,
    handleDialogResult: (inviteCode: string) => void,

}