// TODO : Text field ids

export enum ProfileConstants {

    // FIRST_NAME_ID = 'firstName',
    // FIRST_NAME_CHANGED_ATTR = 'firstNameChanged',
    // FIRST_NAME_BLURRED_ATTR = 'firstNameBlurred',

    // LAST_NAME_ID = 'lastName',
    // LAST_NAME_CHANGED_ATTR = 'lastNameChanged',
    // LAST_NAME_BLURRED_ATTR = 'lastNameBlurred',

    EMAIL_ID = 'email',
    PHONE_NUMBER_ID = 'phoneNumber',
    ADDRESS_ID = 'address',
    WORKLOAD_ID = 'workload',

    EMPLOYER_NAME_ID = 'employerName',
    EMPLOYER_EMAIL_ID = "employerEmail",
    EMPLOYER_PHONE_NUMBER_ID = 'employerPhoneNumberId',

    // EMAIL_CHANGED_ATTR = 'emailChanged',
    // EMAIL_BLURRED_ATTR = 'emailBlurred',

    // PASSWORD_ID = 'password',
    // PASSWORD_CHANGED_ATTR = 'passwordChanged',
    // PASSWORD_BLURRED_ATTR = 'passwordBlurred',

    // COMPANY_ID = 'company',
    // COMPANY_CHANGED_ATTR = 'companyChanged',

    // EMPLOYER_SIGN_UP_ID = 'employerSignUp',
    // EMPLOYER_SIGN_UP_CHANGED_ATTR = 'employerSignUpChanged',

    // PRIVACY_POLICY_ID = 'agreePrivacyPolicyAndTermsOfUse',
    // PRIVACY_POLICY_CHANGED_ATTR = 'agreePrivacyPolicyAndTermsOfUseChanged',

    // INVALID_FIRST_NAME = 'Invalid first name',
    // INVALID_LAST_NAME = 'Invalid last name',
    // INVALID_EMAIL = 'Invalid email',
    // INVALID_PASSWORD = 'Invalid password',
    // INVALID_INVITE_CODE = "Invalid invite code",
    // TAKEN_INVITE_CODE = "This invite code is already taken"

}
