import Footer from "./Footer";
import React from "react";
import {makeStyles} from "@material-ui/core";
import Dimensions from "./constants/Dimensions";
import Colors from "./constants/Colors";


export default function TermsOfUse() {

    const styles = useStyles()

    return (
        <div className={styles.root}>

            <h6 className={styles.title}>Terms of use</h6>
            <p className={styles.titleDescription}>Available soon.</p>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "center",
        // justifyContent: 'center'
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: Dimensions.SECTION_PADDING_TOP,
        width: Dimensions.PAGE_WIDTH,
    },
    title: {
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,
        margin: 0,
        padding: 0,
    },
    titleDescription: {
        // padding: '20px',
        // marginLeft: '15%',
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.SECONDARY_TEXT_COLOR,
    }
}))