import {makeStyles} from "@material-ui/core";
import {shiftReference} from "../../firebase/realtime/References";
import {useEffect, useState} from "react";
import {getShift} from "../../firebase/realtime/Listeners";
import {Shift as ShiftModel} from "../../firebase/realtime/Models";
import {areShiftPropsValid, ShiftProps} from "../../props/AttendanceProps";

export default function ShiftPanel(props: ShiftProps) {

    const styles = useStyles()

    const [shift, setShift] = useState<ShiftModel | null>(null)

    useEffect(() => {

        if (!areShiftPropsValid(props)) {
            console.log("Provided shift props are not valid")
            return
        }

        const shiftRef = shiftReference(props.userId, props.groupId, props.yearKey, props.monthKey, props.shiftKey)

        getShift(shiftRef, (shift) => {
            if (shift == null) {
                console.log("Retrieved shift is null")
            }
            console.log("Shift retrieved")
            setShift((shift))
        })
    })

    return (
        <div className={styles.root}>

            <h1>Shift</h1>

        </div>
    )
}

// interface ShiftProps {
//     userId: string,
//     groupId: string,
//     yearKey: number,
//     monthKey: number,
//     shiftKey: string,
// }



const useStyles = makeStyles(theme => ({

    root: {}


}))