import Constants from "../constants/Constants";
import RegexConstants from "../constants/RegexConstants";

export function isNameValid(name: string): boolean {

    let validName = true

    if (name.length === 0) {
        validName = false
    }

    // Android
    // const regex: RegExp = /[\\p{Lu}][\\p{L} .'-]*/

    // TODO : Allow diacritics
    const regex : RegExp = /^[a-zA-Z]+$/

    if (!name.match(regex)) {
        validName = false
    }

    return validName
}

export function isEmailValid(email: string) {

    // https://stackoverflow.com/a/46181/12580605

    return email.toLowerCase().match(RegexConstants.EMAIL_REGEX);
}

export function isPasswordValid(password: string) : boolean {
    return password.length > 5
}

export function isInviteCodeValid(inviteCode : string) : boolean {

    if (inviteCode.length < Constants.INVITE_CODE_MIN_LENGTH) {
        console.log("Invite code is too short")
        return false
    }
    if (inviteCode.length > Constants.INVITE_CODE_MAX_LENGTH) {
        console.log("Invite code is too long")
        return false
    }
    // Invite code should contain only letters and numbers.
    if (!inviteCode.match(RegexConstants.LETTERS_NUMBERS_TEXT_REGEX)) {
        console.log("Invite code doesn't match regex")
        return false
    }

    return true
}