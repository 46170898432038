import firebase from "firebase";
import {ChildrenConstants} from "./ChildrenConstants";

const firestore = firebase.firestore()

export function getUserDocumentReference(userId: string) {
    return firestore.collection(ChildrenConstants.USERS).doc(userId)
}

export function getInviteCodeDocumentReference(inviteCode: string) {
    return firestore.collection(ChildrenConstants.INVITE_CODES).doc(inviteCode)
}

export function getGroupDocumentReference(groupId: string) {
    return firestore.collection(ChildrenConstants.GROUPS).doc(groupId)
}

export function getCompanyDocumentReference(companyId: string) {
    return firestore.collection(ChildrenConstants.COMPANIES).doc(companyId)
}

export function getMembersCollectionReference(groupId : string) {
    return firestore.collection(ChildrenConstants.GROUPS).doc(groupId).collection(ChildrenConstants.MEMBERS)
}
