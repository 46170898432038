import {LoginConstants} from "./LoginConstants";
import {UserSerializable} from "../firebase/firestore/Models";

export enum SignUpType {
    EMAIL,
    FACEBOOK,
}

export interface SignUpState {

    firstName: string,
    firstNameChanged: boolean,
    firstNameBlurred: boolean,

    lastName: string,
    lastNameChanged: boolean
    lastNameBlurred: boolean

    email: string,
    emailChanged: boolean,
    emailBlurred: boolean,

    password: string,
    passwordChanged: boolean
    passwordBlurred: boolean

    employerSignUp: boolean
    employerSignUpChanged: boolean

    company: string,
    companyChanged: boolean
    companyBlurred: boolean

    agreePrivacyPolicyAndTermsOfUse: boolean
    agreePrivacyPolicyAndTermsOfUseChanged: boolean

    formSubmitted: boolean,
    signUpType : SignUpType,
}

export const initialSignUpState : () => SignUpState = () => {
    return ({

        firstName: "",
        firstNameChanged: false,
        firstNameBlurred: false,

        lastName: "",
        lastNameChanged: false,
        lastNameBlurred: false,

        email: "",
        emailChanged: false,
        emailBlurred: false,

        password: "",
        passwordChanged: false,
        passwordBlurred: false,

        employerSignUp: false,
        employerSignUpChanged: false,

        company: "",
        companyChanged: false,
        companyBlurred: false,

        agreePrivacyPolicyAndTermsOfUse: false,
        agreePrivacyPolicyAndTermsOfUseChanged: false,

        formSubmitted: false,
        signUpType: SignUpType.EMAIL,
    })
}

/**
 * @return Corresponding variable name for given id, that matches with text field in form.
 */
export function getChangedAttribute(id: String): string {
    switch (id) {
        case LoginConstants.FIRST_NAME_ID :
            return LoginConstants.FIRST_NAME_CHANGED_ATTR
        case LoginConstants.LAST_NAME_ID :
            return LoginConstants.LAST_NAME_CHANGED_ATTR
        case LoginConstants.EMAIL_ID :
            return LoginConstants.EMAIL_CHANGED_ATTR
        case LoginConstants.PASSWORD_ID :
            return LoginConstants.PASSWORD_CHANGED_ATTR
        case LoginConstants.EMPLOYER_SIGN_UP_ID :
            return LoginConstants.EMPLOYER_SIGN_UP_CHANGED_ATTR
        case LoginConstants.COMPANY_ID:
            return LoginConstants.COMPANY_CHANGED_ATTR
        case LoginConstants.PRIVACY_POLICY_ID:
            return LoginConstants.PRIVACY_POLICY_CHANGED_ATTR
        default :
            return ""
    }
}

export function getBlurredAttribute(id: String): string {
    switch (id) {
        case LoginConstants.FIRST_NAME_ID :
            return LoginConstants.FIRST_NAME_BLURRED_ATTR
        case LoginConstants.LAST_NAME_ID :
            return LoginConstants.LAST_NAME_BLURRED_ATTR
        case LoginConstants.EMAIL_ID :
            return LoginConstants.EMAIL_BLURRED_ATTR
        case LoginConstants.PASSWORD_ID :
            return LoginConstants.PASSWORD_BLURRED_ATTR
        default :
            return ""
    }
}

export function convertToUserSerializable(state : SignUpState) : UserSerializable  {
    return ({
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phoneNumber: '',
        address: '',
        employer: state.employerSignUp,
        iconId: '',
        workLoad: '',
        groupIdPreference: '',
        companyIdPreference: '',
        nightModePreference: true,
    })
}