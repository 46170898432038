

export default abstract class NavigationPaths {

    // Main pages
    static readonly HOME = "/"
    static readonly ABOUT = "/about"
    static readonly HELP = "/help"
    static readonly SIGN_IN = "/sign_in"
    static readonly SIGN_UP = "/sign_up"
    static readonly OVERVIEW = "/overview"
    static readonly SETTINGS = "/settings"
    static readonly PLUGINS = "/plugins"
    static readonly PRIVACY_POLICY = "/privacy_policy"
    static readonly TERMS_OF_USE = "/terms_of_use"
    static readonly INVALID_PAGE = "/invalid_page"

    // Overview
    static readonly ATTENDANCE = "/attendance"
    static readonly PROFILE = "/profile"
    static readonly TASKS = "/tasks"

}