import firebase from "firebase"
import Reference = firebase.database.Reference
import {Shift, UserPresence} from "./Models";
import {firebaseConfig} from "../utils/FirebaseConfig";
import {shiftsReference, userPresenceReference} from "./References";
import {areShiftsPropsValid, ShiftsProps} from "../../props/AttendanceProps";

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Retrieving lists of data (firebase) https://firebase.google.com/docs/database/web/lists-of-data

export function getUserPresence(userId : string, onResult: (result: UserPresence | null) => void) {

    const userPresenceRef = userPresenceReference(userId)

    userPresenceRef.get().then((snapshot) => {
        const userPresence: UserPresence = snapshot.val()
        onResult(userPresence)
    }).catch((error) => {
        console.error("Failed to retrieve user presence: " + error)
        onResult(null)
    })
}

export function getShifts(props : ShiftsProps,
                             onResult: (result: Array<[string, Shift]> | null) => void) {

    if (!areShiftsPropsValid(props)) {
        console.log("Shifts props are not provided")
        return
    }

    const shiftsRef = shiftsReference(props.userId, props.groupId, props.yearKey, props.monthKey)

    shiftsRef
        // .orderByChild('startTime') // TODO
        .get()
        .then((snapshot) => {

            // const shifts: Map<string, Shift> = new Map<string, Shift>()
            const shifts = new Array<[string, Shift]>();

            console.log("Snapshot " + JSON.stringify(snapshot))

            snapshot.forEach((childSnapshot) => {
                const key = childSnapshot.key
                const shift : Shift = childSnapshot.val()
                if (key != null && shift != null) {
                    shifts.push([key, shift])
                }
            })

            // for (let shift of shifts) {
            //     console.log(JSON.stringify(shift))
            //     console.log(shift.startTime)
            // }
            // shifts.forEach(((value, key) => {
            //     console.log("Shift key " + key)
            //     console.log(value.startTime)
            // }))
            // let keys = Array.from( myMap.keys() );

            onResult(shifts)

        }).catch((error) => {
        console.log("Failed to retrieve shifts: " + error)
        onResult(null)
    })
}


export function getShiftsMap(props : ShiftsProps,
                          onResult: (result: Map<string, Shift> | null) => void) {

    if (!areShiftsPropsValid(props)) {
        console.log("Shifts props are not provided")
        return
    }

    const shiftsRef = shiftsReference(props.userId, props.groupId, props.yearKey, props.monthKey)

    shiftsRef
        // .orderByKey('startTime') // TODO
        .get()
        .then((snapshot) => {

            const shifts: Map<string, Shift> = new Map<string, Shift>()

            snapshot.forEach((childSnapshot) => {
                const key = childSnapshot.key
                const shift : Shift = childSnapshot.val()
                if (key != null && shift != null) {
                    shifts.set(key, shift)
                }
            })

            // for (let shift of shifts) {
            //     console.log(JSON.stringify(shift))
            //     console.log(shift.startTime)
            // }
            // shifts.forEach(((value, key) => {
            //     console.log("Shift key " + key)
            //     console.log(value.startTime)
            // }))
            // let keys = Array.from( myMap.keys() );

            onResult(shifts)

        }).catch((error) => {
        console.log("Failed to retrieve shifts: " + error)
        onResult(null)
    })
}

export function getShift(ref: Reference, onResult: (result: Shift | null) => void) {
    ref.get().then((snapshot) => {

        const shift: Shift = snapshot.val()
        onResult(shift)
    }).catch((error) => {
        console.log("Failed to retrieve shifts: " + error)
        onResult(null)
    })
}

