import {navigationStyles} from "./NavigationStyles";
import NavigationPaths from "../constants/NavigationPaths";
import {AppBar, Box, Toolbar, Typography} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import {Divider, IconButton} from "@mui/material";
import {Link} from 'react-router-dom';
import {redirectAbout, redirectHelp, redirectHome, redirectSignIn, redirectSignUp} from "./Redirections";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from '@mui/icons-material/Person';
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import firebase from "firebase/app";
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/FormatListBulleted';
import {useContext, useState} from "react";
import {AppDataContext} from "../AppDataContext";
import {Redirect} from "react-router-dom";
import Colors from "../constants/Colors";
import LogOutDialog from "../login/LogOutDialog";
import MenuIcon from "@mui/icons-material/Menu";
// https://mui.com/components/dialogs/

export default function AppNavigation() {

    const styles = navigationStyles()

    const appData = useContext(AppDataContext)

    const [mobileAnchorEl, setMobileAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMobileMenu = Boolean(mobileAnchorEl);
    const handleMobileMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        console.log("Opening mobile menu")
        setMobileAnchorEl(event.currentTarget);
    };
    const handleMobileCloseMenu = () => {
        setMobileAnchorEl(null);
    };

    return (

        <Box sx={{flexGrow: 1}}>

            <AppBar position="static" className={styles.navigation}>
                <Toolbar>

                    {/*<IconButton*/}
                    {/*    size={"medium"}*/}
                    {/*    edge="start"*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="menu"*/}
                    {/*    // sx={{ mr: 2 }}*/}
                    {/*>*/}
                    {/*</IconButton>*/}

                    <a className={styles.titleItem} onClick={redirectHome} href={NavigationPaths.HOME}>
                        <Typography variant="h6" className={styles.title}>
                            Work Tracking
                        </Typography>
                    </a>

                    <Stack direction="row" spacing={2} className={styles.navItemsStack}>

                    </Stack>

                    <div
                        // direction="row"
                        // divider={<Divider orientation="vertical" flexItem style={{background: 'gray'}}/>}
                        // spacing={2}
                        className={styles.loginStackContent}
                    >

                        <AppMenuStack/>

                        <IconButton className={styles.mobileMenuIconButton}
                                    id="basic-button"
                                    aria-controls={openMobileMenu ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMobileMenu ? 'true' : undefined}
                                    onClick={handleMobileMenuItemClick}>
                            <MenuIcon fontSize={"medium"} className={styles.mobileMenuIcon}/>
                        </IconButton>

                    </div>

                </Toolbar>
            </AppBar>
        </Box>
    )
}


function AppMenuStack() {

    const styles = navigationStyles()

    const appData = useContext(AppDataContext)

    // User menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // Log out dialog
    const [logOutDialogOpen, setLogOutDialogOpen] = React.useState(false);
    const openLogOutDialog = () => {
        handleCloseMenu();
        setLogOutDialogOpen(true);
    };
    const closeLogOutDialog = () => {
        setLogOutDialogOpen(false);
    };

    return (
        <Stack
            direction="row"
            spacing={2}
            className={styles.loginStackDesktop}
        >

            {/* Overview button */}
            <IconButton className={styles.menuIconButton} href={NavigationPaths.OVERVIEW}>
                <ListIcon className={styles.menuIcon}/>
            </IconButton>

            {/* User button that opens dropdown menu */}
            <IconButton className={styles.menuIconButton}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuItemClick}>
                <PersonIcon className={styles.menuIcon}/>
            </IconButton>

            {/* Settings button */}
            <IconButton className={styles.menuIconButton} href={NavigationPaths.SETTINGS}>
                <SettingsIcon className={styles.menuIcon}/>
            </IconButton>

            {/* Dropdown menu */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        backgroundColor: Colors.PRIMARY_BACKGROUND_COLOR,
                        color: Colors.PRIMARY_TEXT_COLOR,
                    },
                }}
            >
                <MenuItem component={Link} to={NavigationPaths.PROFILE} onClick={handleCloseMenu}>Profile</MenuItem>
                <MenuItem onClick={openLogOutDialog}>Logout</MenuItem>
            </Menu>

            <LogOutDialog handleCloseDialog={closeLogOutDialog} open={logOutDialogOpen}/>

        </Stack>
    );
}

// TODO : Dropdown menu Overview, profile, settings, logout

