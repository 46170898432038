import React, {useContext, useMemo} from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {Route, Switch} from "react-router";
import Home from "./home/Home";
import About from "./about/About";
import WebNavigation from "./navigation/WebNavigation";
import NavigationPaths from "./constants/NavigationPaths";
import Footer, {FOOTER_HEIGHT} from "./Footer";
import Overview from "./overview/Overview";
import {makeStyles} from "@material-ui/core";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import InvalidPage from "./InvalidPage";
import Plugins from "./plugins/Plugins";
import Help from "./help/Help";
import SignUp from "./login/SignUp";
import SignIn from "./login/SignIn";
import {NAVIGATION_HEIGHT} from "./navigation/NavigationStyles";
import Profile from "./profile/Profile";
import Settings from "./settings/Settings";
import AppNavigation from "./navigation/AppNavigation";
import {AppDataProvider} from "./AppDataProvider";
import Colors from "./constants/Colors";

export default function App() {

    const styles = useStyles()

    // Init firebase
    // if (!firebase.apps.length) {
    //     firebase.initializeApp(firebaseConfig);
    // }
    // const firebaseApp = firebase.initializeApp(firebaseConfig);
    // const user = firebaseApp.auth().currentUser
    // console.log("User is null: " + user == null)

    // const user = useContext(AuthContext);

    // https://www.npmjs.com/package/@types/facebook-js-sdk
    // (window as any).fbAsyncInit = function () {
    //     FB.init({
    //         appId: '2625762540858641',
    //         autoLogAppEvents: true,
    //         xfbml: true,
    //         version: 'v13.0'
    //     });
    // };

    // const webNavigation = useMemo(() => (<WebNavigation/>), [])

    return (

        <div className={styles.root}>

            {/*<div className={styles.content}>*/}

            <AppDataProvider>

                <BrowserRouter>
                    <Switch>

                        {/* Default empty path - exact! */}
                        <Route exact path={NavigationPaths.HOME}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <Home/>
                            </div>
                            <Footer/>

                        </Route>


                        <Route path={NavigationPaths.ABOUT}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <About/>
                            </div>
                            <Footer/>

                        </Route>

                        <Route path={NavigationPaths.HELP}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <Help/>
                            </div>
                            <Footer/>
                        </Route>

                        <Route path={NavigationPaths.SIGN_UP}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <SignUp/>
                            </div>
                            <Footer/>

                        </Route>

                        <Route path={NavigationPaths.SIGN_IN}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <SignIn/>
                            </div>
                            <Footer/>

                        </Route>

                        <Route path={NavigationPaths.PRIVACY_POLICY}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <PrivacyPolicy/>
                            </div>
                            <Footer/>

                        </Route>

                        <Route path={NavigationPaths.TERMS_OF_USE}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <TermsOfUse/>
                            </div>
                            <Footer/>

                        </Route>

                        <Route path={NavigationPaths.PLUGINS}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <Plugins/>
                            </div>
                            <Footer/>

                        </Route>

                        <Route path={NavigationPaths.INVALID_PAGE}>

                            <WebNavigation/>
                            <div className={styles.webContent}>
                                <InvalidPage/>
                            </div>
                            <Footer/>

                        </Route>

                        <Route path={NavigationPaths.OVERVIEW}>

                            <AppNavigation/>
                            <div className={styles.appContent}>
                                <Overview/>
                            </div>

                        </Route>

                        <Route path={NavigationPaths.PROFILE}>

                            <AppNavigation/>
                            <div className={styles.appContent}>
                                <Profile/>
                            </div>

                        </Route>

                        <Route path={NavigationPaths.SETTINGS}>

                            <AppNavigation/>
                            <div className={styles.appContent}>
                                <Settings/>
                            </div>

                        </Route>

                    </Switch>
                </BrowserRouter>
            </AppDataProvider>

        </div>
    );
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.SECONDARY_BACKGROUND_COLOR,
        color: 'white',
        margin: 0,
        padding: 0,

        // Whole page visible
        minHeight: '100vh',
    },
    webContent: {

        // Navigation bar has fixed position
        paddingTop: NAVIGATION_HEIGHT,

        // height: '60vw',

        // Make sure there's space for full page, excluding navigation and footer
        minHeight: `calc(100vh - (${NAVIGATION_HEIGHT} + ${FOOTER_HEIGHT}))`,
    },
    appContent: {

        // Navigation bar has fixed position
        paddingTop: NAVIGATION_HEIGHT,

        // height: '60vw',

        // Make sure there's space for full page, excluding navigation and footer
        minHeight: `calc(100vh - ${NAVIGATION_HEIGHT})`,
    }

});
