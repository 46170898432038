import {makeStyles, Typography} from "@material-ui/core";
import React from "react";


export default function UnavailablePanel() {

    const styles = useStyles()

    return (
        <div className={styles.root}>

            <h1>Not available yet</h1>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {},
    unavailableText: {}
}))