import {
    AppBar,
    Box,
    Toolbar,
    Typography
} from "@material-ui/core";
import NavigationPaths from "../constants/NavigationPaths";
import Stack from '@mui/material/Stack';
import {Divider, Icon, IconButton} from "@mui/material";
import {navigationStyles} from "./NavigationStyles";
import LoginIcon from '@mui/icons-material/Login';
import firebase from "firebase/app";
import {useContext, useEffect, useMemo, useState} from "react";
import {AppDataProvider} from "../AppDataProvider";
import {AppDataContext} from "../AppDataContext";
import MenuIcon from '@mui/icons-material/Menu';
import Colors from "../constants/Colors";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "react-router-dom";
import Menu from "@mui/material/Menu";
import * as React from "react";
import PersonIcon from "@mui/icons-material/Person";
import {Property} from "csstype";
import {firebaseConfig} from "../firebase/utils/FirebaseConfig";

// https://mui.com/components/app-bar/

export default function WebNavigation() {
    // const history = useHistory();

    const appData = useContext(AppDataContext)
    const user = appData.user

    const styles = navigationStyles()

    const [mobileAnchorEl, setMobileAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMobileMenu = Boolean(mobileAnchorEl);
    const handleMobileMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMobileAnchorEl(event.currentTarget);
    };
    const handleMobileCloseMenu = () => {
        setMobileAnchorEl(null);
    };

    return (

        <Box sx={{flexGrow: 1}}>

            <AppBar position="static" className={styles.navigation}>
                <Toolbar>

                    <a className={styles.titleItem} href={NavigationPaths.HOME}>
                        <Typography variant="h6" className={styles.title}>
                            Work Tracking
                        </Typography>
                    </a>

                    <Stack direction="row" spacing={2} className={styles.navItemsStack}>

                        <a className={styles.navItem} href={NavigationPaths.HOME}>
                            <Typography variant="h6" className={styles.navItemText}>
                                Home
                            </Typography>
                        </a>

                        <a className={styles.navItem} href={NavigationPaths.ABOUT}>
                            <Typography variant="h6" className={styles.navItemText}>
                                About
                            </Typography>
                        </a>

                        <a className={styles.navItem} href={NavigationPaths.HELP}>
                            <Typography variant="h6" className={styles.navItemText}>
                                Help
                            </Typography>
                        </a>

                    </Stack>

                    <div className={styles.loginStackContent}>

                        {/* Desktop */}
                        <LoginStack/>

                    </div>

                    {/* Mobile devices (<sm) */}
                    <IconButton className={styles.mobileMenuIconButton}
                                id="basic-button"
                                aria-controls={openMobileMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMobileMenu ? 'true' : undefined}
                                onClick={handleMobileMenuItemClick}>
                        <MenuIcon fontSize={"medium"} className={styles.mobileMenuIcon}/>
                    </IconButton>

                </Toolbar>

                {/* Mobile devices menu */}
                <Menu
                    id="basic-menu"
                    anchorEl={mobileAnchorEl}
                    open={openMobileMenu}
                    onClose={handleMobileCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                        style: {
                            backgroundColor: Colors.PRIMARY_BACKGROUND_COLOR,
                            color: Colors.PRIMARY_TEXT_COLOR,
                        },
                    }}
                >
                    <MenuItem component={Link} to={NavigationPaths.HOME}
                              onClick={handleMobileCloseMenu}>Home</MenuItem>
                    <MenuItem component={Link} to={NavigationPaths.ABOUT}
                              onClick={handleMobileCloseMenu}>About</MenuItem>
                    <MenuItem component={Link} to={NavigationPaths.HELP}
                              onClick={handleMobileCloseMenu}>Help</MenuItem>

                    {/*    if (user) {*/}
                    {/*    return (*/}
                    {/*    <IconButton className={styles.loginButton} href={NavigationPaths.OVERVIEW}>*/}
                    {/*    <LoginIcon className={styles.loginIcon}/>*/}
                    {/*    </IconButton>*/}
                    {/*    )*/}
                    {/*} else {*/}

                    {/*    return (*/}

                    {/*{{user}}*/}

                    <MenuItem component={Link} to={NavigationPaths.SIGN_UP} onClick={handleMobileCloseMenu}>Sign
                        up</MenuItem>
                    <MenuItem component={Link} to={NavigationPaths.SIGN_IN} onClick={handleMobileCloseMenu}>Sign
                        in</MenuItem>
                    {/*<MenuItem onClick={openLogOutDialog}>Logout</MenuItem>*/}

                </Menu>

            </AppBar>
        </Box>
    )
}

function LoginStack() {

    const styles = navigationStyles()

    const appData = useContext(AppDataContext)
    const user = appData.firebaseUser

    const loginStackVisible = user !== undefined && user == null
    const proceedVisible = user !== undefined && user != null

    return (

        <div style={{
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            // position: 'relative',
        }}>

            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem style={{background: 'gray'}}/>}
                spacing={2}
                className={styles.loginStackDesktop}
                style={{
                    // position: 'absolute',
                    // margin: 'auto',
                    visibility: loginStackVisible ? "visible" : "hidden",

                    // position: 'absolute',
                    // marginLeft: 'auto',
                }}
            >
                <a className={styles.loginItem}
                   href={NavigationPaths.SIGN_UP}>
                    <Typography variant="h6" className={styles.navItemText}>
                        Sign up
                    </Typography>
                </a>

                <a className={styles.loginItem}
                   href={NavigationPaths.SIGN_IN}>
                    <Typography variant="h6" className={styles.navItemText}>
                        Sign in
                    </Typography>
                </a>
            </Stack>

            <IconButton className={styles.loginButton}
                        href={NavigationPaths.OVERVIEW}
                        style={{

                            // position: 'absolute',
                            // marginLeft: 'auto',
                            // zIndex: 2,

                            visibility: proceedVisible ? "visible" : "hidden",
                        }}
            >
                <LoginIcon className={styles.loginIcon}/>
            </IconButton>

        </div>


    )
}

// Blue button sample
// const blueTheme = createTheme({ palette: { primary: blue } })
// MuiThemeProvider theme={blueTheme}>
//     <Button variant="outlined" color="primary" href={NavigationPaths.LOGIN} onClick={redirectLogin}
// className={styles.loginButton}>
//     Login
//     </Button>
// </MuiThemeProvider>


