export default abstract class Colors {

    // Android
    private static readonly COLOR_PRIMARY_BLUE = "#1E88E5"
    private static readonly COLOR_PRIMARY_DARK_BLUE = "#1976D2"
    static readonly COLOR_ACCENT_BLUE = "#1976D2"
    private static readonly DARK_MODE_COMPONENT = "#212121"
    private static readonly DAY_MODE_COMPONENT = "#F5F5F5"
    private static readonly COLOR_PRIMARY_LIGHT = "#F5F5F5"
    private static readonly COLOR_PRIMARY_TEXT = "#F5F5F5"
    private static readonly COLOR_SECONDARY_TEXT = "#F5F5F5"
    private static readonly ANDROID_DIVIDER = "#F5F5F5"

    // Main colors for web
    static readonly PRIMARY_TEXT_COLOR = "#F5F5F5"
    static readonly SECONDARY_TEXT_COLOR = "lightgray"

    static readonly REDIRECT_SMALL_TEXT_COLOR = "dimgray"
    static readonly REDIRECT_SMALL_TEXT_HOVER_COLOR = "gray"

    static readonly PRIMARY_TEXT_BLUE = "dodgerblue"

    static readonly PRIMARY_BACKGROUND_COLOR = "black"
    static readonly SECONDARY_BACKGROUND_COLOR = Colors.DARK_MODE_COMPONENT

    // static readonly DARK_GRAY = '#181818'
    // rgba(169, 169, 169, 0.05) initial web card gray
    // static readonly CARD_DARK_GRAY = '#181818'

    // Dark gray color for cards with black background
    static readonly CARD_PRIMARY_BACKGROUND_COLOR = '#080808'
    static readonly CARD_PRIMARY_BORDER_COLOR = '#282828'

    // static readonly CARD_SECONDARY_DARK_GRAY
    static readonly CARD_SECONDARY_BACKGROUND_COLOR = '#181818'
    static readonly CARD_SECONDARY_BORDER_COLOR = '#282828'


    static readonly INPUT_BACKGROUND_DARK_GRAY = 'rgba(169, 169, 169, 0.1)'

    // TOOD : New secondary background card color
    // https://www.w3schools.com/colors/colors_shades.asp

}