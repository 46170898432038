import {makeStyles, Switch, SwitchProps} from "@material-ui/core";
import Colors from "../constants/Colors";
import * as React from "react";

// https://mui.com/components/switches/
// styling switch https://stackoverflow.com/a/65340043/12580605

export default function CustomSwitch(props : SwitchProps) {

    const styles = useStyles()

    return (
        <Switch
            {...props}
            classes={{
                track: styles.switch_track,
                switchBase: styles.switch_base,
            }}
            color="primary"/>
    )
}

const useStyles = makeStyles({
    switch_track: {
        backgroundColor: Colors.PRIMARY_TEXT_BLUE,
    },
    switch_base: {
        // color: Colors.PRIMARY_TEXT_BLUE,
        "&.Mui-disabled": {
            color: Colors.PRIMARY_TEXT_BLUE,
        },
        "&.Mui-checked": {
            color: Colors.PRIMARY_TEXT_BLUE,
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: Colors.PRIMARY_TEXT_BLUE,
        }
    },
    // switch_primary: {
    //     "&.Mui-checked": {
    //         color: "#4CAF50",
    //     },
    //     "&.Mui-checked + .MuiSwitch-track": {
    //         backgroundColor: "#4CAF50",
    //     },
    // },
})
