import {TextFieldProps} from "@mui/material/TextField/TextField";
import {TextField} from "@mui/material";
import {Box, makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import Colors from "../constants/Colors";

export default function LoginTextField(props: TextFieldProps) {

    const styles = useStyles()
    const textFieldStyle = {
        input: {color: 'white'},
        label: {
            // color: 'white',
        },
    }

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // https://stackoverflow.com/q/60884824/12580605
    const textFieldLabelProps = {
        classes: {
            root: styles.textFieldLabel,
            filled: styles.textFieldLabelFilled,
            focused: styles.textFieldLabelFocused,
        }
    }

    return (
        <Box sx={{width: isMobile ? 350 : 500,}}>
            <TextField {...props}
                       variant="standard"
                       size={"medium"}
                       sx={textFieldStyle}
                       InputLabelProps={textFieldLabelProps}
                       className={styles.textField}
                       fullWidth={true}/>
        </Box>
    )
}

const useStyles = makeStyles({
    textField: {
        // width: '50%',
        // fontSize: '15px',

        borderBottom: 'white',
        width: '100px',
        // color: "white",
        //
        // '& .MuiInputBase-input': {
        //     color: '#fff', // Text color
        // },

        '& .MuiInput-underline:before': {
            borderBottomColor: "gray",
        },

        '& .MuiInput-underline:onHover': {
            borderBottomColor: "gray",
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: "dodgerblue",
        },


        // TODO : override hover underline color

        // '& .MuiInput-underline': {
        //     borderBottomColor: "gray",
        // },
        //

        //
        // '& .MuiInput-underline:hover:before': {
        //     borderBottomColor: '#fff', // Solid underline on hover
        // },

    },
    textFieldLabel: {
        color: `${Colors.SECONDARY_TEXT_COLOR} !important`,
    },
    textFieldLabelFocused: {
        color: `${Colors.PRIMARY_TEXT_BLUE} !important`,
    },
    textFieldLabelFilled: {
        color: `${Colors.PRIMARY_TEXT_BLUE} !important`,
    },
})

