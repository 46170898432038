import {makeStyles} from "@material-ui/core";
import Dimensions from "../../constants/Dimensions";
import {ListItem, ListItemText} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Shift} from "../../firebase/realtime/Models";


export default function ShiftItem(props: ShiftItemProps) {

    const styles = useStyles()

    // <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    //     {[1, 2, 3].map((value) => (
    //         <ListItem
    //             key={value}
    //             disableGutters
    //             secondaryAction={
    //                 <IconButton>
    //                     <CommentIcon />
    //                 </IconButton>
    //             }
    //         >
    //             <ListItemText primary={`Line item ${value}`} />
    //         </ListItem>
    //     ))}
    // </List>

    // https://mui.com/components/lists/

    const shiftKey = props.shiftKey

    const handleItemClick = () => {
        props.handleShiftOpen(shiftKey)
    }

    return (
        <ListItem
            key={shiftKey}
            disableGutters
            secondaryAction={
                // <IconButton>
                //     <CommentIcon />
                // </IconButton>

                <ArrowForwardIosIcon fontSize={'small'}/>
            }
            onClick={handleItemClick}
        >
            <ListItemText primary={`Line item ${shiftKey}`}/>
        </ListItem>
    )
}

const useStyles = makeStyles({
    root: {
        padding: Dimensions.TAB_PANEL_PADDING
    },

});

interface ShiftItemProps {
    shiftKey: string
    shiftModel : Shift
    handleShiftOpen: (shiftKey: string) => void
}