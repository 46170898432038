

export abstract class ChildrenConstants {

    // Top paths
    static readonly USERS = "users"
    static readonly GROUP_MANAGERS = "group_managers"
    static readonly USER_PRESENCE = "user_presence"
    static readonly APP_RELEASE_AWAITING = "app_release_awaiting"

    // Shift children
    static readonly SHIFTS = "shifts"
    static readonly PAUSES = "pauses"
    static readonly SHIFT_LOCATIONS = "shift_locations"
    static readonly SHIFT_NOTES = "shift_notes"
    static readonly SHIFT_CHANGES = "shift_changes"
    static readonly SHIFT_DELETED = "shift_deleted"
    static readonly MONTH_SUMMARIES = "month_summaries"
    static readonly ATTENDANCE_PERIODS = "attendance_periods"

    // Shift location children
    static readonly START_TRACKING = "start_tracking"
    static readonly STOP_TRACKING = "stop_tracking"
    static readonly START_PAUSE = "start_pause"
    static readonly STOP_PAUSE = "stop_pause"
    static readonly TIMESTAMP = "timestamp"

    // Task children
    static readonly TASKS = "tasks"
    static readonly SUB_TASKS = "sub_tasks"

}