import firebase from "firebase";
import {firebaseConfig} from "../utils/FirebaseConfig";
import {AddAwaitingUserRequestData, CreateEmployeeRequestData, CreateEmployerRequestData} from "./RequestData";
import FunctionConstants from "./FunctionConstants";
import HttpsCallableResult = firebase.functions.HttpsCallableResult;
import {useContext} from "react";
import {AppDataContext} from "../../AppDataContext";

// const functions = getFunctions();
// const addMessage = httpsCallable(functions, 'addMessage');
// addMessage({ text: messageText })
//     .then((result) => {
//         // Read result of the Cloud Function.
//         /** @type {any} */
//         const data = result.data;
//         const sanitizedMessage = data.text;
//     });
//
// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
// }

// const functions = firebase.functions()

// https://firebase.google.com/docs/functions/callable#web-version-9_2

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const functions = firebase.functions()

export function addAwaitingUser(requestData: AddAwaitingUserRequestData, callback: (success: boolean) => void) {

    const addAwaitingUser = functions.httpsCallable(FunctionConstants.ADD_AWAITING_USER)

    const data = JSON.stringify(requestData)

    addAwaitingUser(data)
        .then((value) => {
            console.log("Add awaiting user result " + JSON.stringify(value.data))
            callback(true)
        })
        .catch((error) => {
            console.log("Add awaiting user result " + JSON.stringify(error))
            callback(false)
        })
}

export function createEmployer(requestData: CreateEmployerRequestData, callback: (success: boolean) => void) {

    const createEmployer = functions.httpsCallable(FunctionConstants.CREATE_EMPLOYER)

    const data = JSON.stringify(requestData)

    createEmployer(data)
        .then((value) => {
            console.log("Create employer result " + JSON.stringify(value.data))
            callback(true)
        })
        .catch((error) => {
            console.log("Create employer error " + JSON.stringify(error))
            callback(false)
        })
}

export function createEmployee(requestData: CreateEmployeeRequestData, callback: (success: boolean) => void) {

    const createEmployee = functions.httpsCallable(FunctionConstants.CREATE_EMPLOYEE)

    const data = JSON.stringify(requestData)

    createEmployee(data)
        .then((value) => {
            console.log("Create employee result " + JSON.stringify(value.data))
            callback(true)
        })
        .catch((error) => {
            console.log("Create employee error " + JSON.stringify(error))
            callback(false)
        })
}