// import {Button, Checkbox, FormControlLabel, FormGroup, makeStyles, Paper, Typography} from "@material-ui/core";
import {Alert, Box, Button, Collapse, IconButton, Stack, TextField} from "@mui/material";
import React, {useContext, useState} from "react";
import {AppDataContext} from "../AppDataContext";
import NavigationPaths from "../constants/NavigationPaths";
import {authButtonStyle, facebookButtonStyle, useLoginStyles} from "./LoginStyles";
import Colors from "../constants/Colors";
import LoginTextField from "./LoginTextField";
import {makeStyles, Typography} from "@material-ui/core";
import {LoginConstants} from "./LoginConstants";
import {Redirect} from "react-router-dom";
import {setUserPresence} from "../firebase/realtime/Updaters";
import {userPresenceReference} from "../firebase/realtime/References";
import CloseIcon from '@mui/icons-material/Close';
import FacebookUnavailableAlert from "./FacebookUnavailableAlert";
import Constants from "../constants/Constants";

export default function SignIn() {

    const styles = useStyles()
    const commonStyles = useLoginStyles()

    const appData = useContext(AppDataContext)

    const [signInState, setSignInState] = useState(getInitialSignInState)
    const [facebookAlertOpen, setFacebookAlertOpen] = useState(false)
    const [selectedField, setSelectedField] = useState(1)
    const emailRef = React.createRef<HTMLInputElement>();
    const passwordRef = React.createRef<HTMLInputElement>();
    const SELECTABLE_COUNT = 2;

    const redirectOverview = (
        // <Redirect to={NavigationPaths.OVERVIEW}/>
        <Redirect to={NavigationPaths.OVERVIEW}/>
    )

    const [loggedIn, setLoggedIn] = useState(appData.firebaseApp?.auth().currentUser != null)
    if (loggedIn) {
        console.log("Redirecting to overview")
        return redirectOverview
        // return (
        //     <Redirect to={NavigationPaths.OVERVIEW}/>
        // )
    }

    // const emailAuthProvider = new EmailAuthProvider();
    // const facebookAuthProvider = new FacebookAuthProvider();

    const updateUserLastTimeSignedIn = (userId: string) => {

        const currentUserPresence = appData.userPresence
        if (userId == null || currentUserPresence == null) {
            return
        }

        const currentTime = new Date().toISOString()
        const userPresence = {
            lastTimeActive: currentUserPresence?.lastTimeActive,
            lastTimeSignedIn: currentTime,
        }

        setUserPresence(userId, userPresence, (success => {
            if (success) {
                console.log("Last time signed in update successfully")
            } else {
                console.log("Failed to update last time signed in")
            }
        }))
    }

    const handleSignInClick = () => {

        if (!Constants.USING_PROD_VERSION) {
            console.log("Sign in state " + JSON.stringify(signInState))
        }

        const auth = appData.firebaseApp?.auth()
        if (auth == null) {
            return
        }

        auth.signInWithEmailAndPassword(signInState.email, signInState.password).then((userCredential) => {

            console.log("Log in successful")
            setLoggedIn(true)

            const userId = userCredential.user?.uid
            if (userId != null) {
                updateUserLastTimeSignedIn(userId)
            }

        }).catch((error) => {
            console.log("Failed to log in: " + error)
        })
    }

    const handleFacebookSignInClick = () => {

        if (Constants.USING_PROD_VERSION) {
            // TODO : Temporary
            setFacebookAlertOpen(true)
            return
        }

        // Call cloud function
        //  https://firebase.google.com/docs/auth/web/facebook-login
    }

    const handleSignInChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        // Id and value of changed field
        const {id, value} = e.target

        const changedField: string = getChangedAuthField(id.toString())

        // Update state of react component
        setSignInState(prevState => ({

            // Restore previous state
            ...prevState,

            // Update value of changed field (var name = field id)
            [id]: value,

            // Update state of changed field
            [changedField]: true
        }))
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        const key = e.key
        let selectField = selectedField;

        if (key === 'ArrowDown') {
            if (selectField < SELECTABLE_COUNT) {
                selectField = selectField + 1
            }
        } else if (key === 'ArrowUp') {
            if (selectField > 1) {
                selectField = selectField - 1
            }
        } else if (key === 'Enter') {
            if (selectField < SELECTABLE_COUNT) {
                selectField = selectField + 1
            }
        }

        if (selectField !== selectedField) {

            switch (selectField) {
                case 1 : {
                    emailRef.current?.focus()
                    break;
                }
                case 2 : {
                    passwordRef.current?.focus()
                    break;
                }
            }

            setSelectedField(selectField)
        }
    }

    const handleTextFieldSelect = (e: React.ChangeEvent<HTMLInputElement>) => {

        const {id, value} = e.target

        switch (id) {
            case LoginConstants.EMAIL_ID : {
                setSelectedField(1)
                break;
            }
            case LoginConstants.PASSWORD_ID : {
                setSelectedField(2)
                break;
            }
        }
    }

    const handleFacebookAlertClose = () => {
        setFacebookAlertOpen(false)
    }

    return (
        <div className={commonStyles.root}>

            <FacebookUnavailableAlert alertOpened={facebookAlertOpen} handleAlertClose={handleFacebookAlertClose}/>

            <div className={commonStyles.content}>

                <Typography variant={"h1"} className={commonStyles.loginTitle}>Sign in</Typography>

                {/*<Paper elevation={3} variant={"outlined"} className={commonStyles.textFieldPaper}>*/}
                <Box component="span"
                     sx={{p: 2, borderRadius: '50px'}}
                     className={commonStyles.textFieldBox}
                     onKeyDown={handleKeyDown}>

                    <Stack spacing={3} direction={"column"} className={commonStyles.textFieldStack}>

                        <LoginTextField
                            id='email'
                            label="Email"
                            type="email"
                            autoComplete="current-email"
                            onChange={handleSignInChange}
                            onSelect={handleTextFieldSelect}
                            autoFocus={selectedField === 1}
                            inputRef={emailRef}/>

                        <LoginTextField id="password"
                                        label="Password"
                                        type="password"
                                        autoComplete="current-password"
                                        onChange={handleSignInChange}
                                        onSelect={handleTextFieldSelect}
                                        inputRef={passwordRef}/>

                    </Stack>

                    <Stack spacing={2}
                           direction="column"
                           className={styles.authButtonStack}>
                        <Button variant="contained"
                                onClick={handleSignInClick}
                                className={commonStyles.signUpButton}
                                sx={authButtonStyle}
                                color="primary">SIGN
                            IN</Button>
                        <Button variant="contained"
                                onClick={handleFacebookSignInClick}
                                color="primary"
                                sx={facebookButtonStyle}
                                className={commonStyles.facebookButton}>SIGN
                            IN WITH FACEBOOK</Button>
                    </Stack>

                    <a className={commonStyles.authRedirectItem} href={NavigationPaths.SIGN_UP}>
                        <Typography variant={'h5'} className={commonStyles.authRedirectText}>Don't have an account?
                            <span style={{fontWeight: 600}}> Sign up</span></Typography>
                    </a>

                </Box>

            </div>
        </div>
    )
}

interface SignInState {

    email: string,
    emailChanged: boolean,

    password: string,
    passwordChanged: boolean
}

/**
 * Initial state for auth dialog hook.
 */
function getInitialSignInState(): SignInState {
    return ({

        email: "",
        emailChanged: false,

        password: "",
        passwordChanged: false,
    })
}

/**
 * @return Corresponding variable name for given id, that matches with text field in form.
 */
function getChangedAuthField(id: String): string {
    switch (id) {
        case 'email' :
            return 'emailChanged'
        case 'password' :
            return 'passwordChanged'
        default :
            return ""
    }
}

const useStyles = makeStyles({
    // root: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    // },
    authButtonStack: {
        marginTop: '50px',
    },
});

