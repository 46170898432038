import {Redirect} from "react-router-dom";
import NavigationPaths from "../constants/NavigationPaths";

export const redirectHome = () => {
    // history.push("/home");
    return (
        <Redirect to={NavigationPaths.HOME}/>
    )
}
export const redirectAbout = () => {
    // history.push("/about");
    return (
        <Redirect to={NavigationPaths.ABOUT}/>
    )
}
export const redirectHelp = () => {
    // history.push("/help");
    return (
        <Redirect to={NavigationPaths.HELP}/>
    )
}
export const redirectSignIn = () => {
    // history.push("/sign_in");
    return (
        <Redirect to={NavigationPaths.SIGN_IN}/>
    )
}
export const redirectSignUp = () => {
    // history.push("/sign_up");
    return (
        <Redirect to={NavigationPaths.SIGN_UP}/>
    )
}

export const redirectOverview = () => {
    return (
        <Redirect to={NavigationPaths.OVERVIEW}/>
    )
}

export const redirectProfile = () => {
    return (
        <Redirect to={NavigationPaths.PROFILE}/>
    )
}

export const redirectSettings = () => {
    return (
        <Redirect to={NavigationPaths.SETTINGS}/>
    )
}