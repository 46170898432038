

export const firebaseConfig = {
    apiKey: "AIzaSyC4JrNzd2t-lZ08v3arveM552Klgr5IN9Q",
    authDomain: "fluid-house-258414.firebaseapp.com",
    databaseURL: "https://fluid-house-258414.firebaseio.com",
    projectId: "fluid-house-258414",
    storageBucket: "fluid-house-258414.appspot.com",
    messagingSenderId: "605255063085",
    appId: "1:605255063085:web:8ecedc9ad91ede8060caa4",
    measurementId: "G-DRTG6BQQXS"
};
