import Colors from "../constants/Colors";
import {Divider} from "@mui/material";
import React from "react";
import {CommonProps} from "@mui/material/OverridableComponent";

/**
 * TODO : Cannot extend styles
 */
export function HorizontalContentDivider(props : CommonProps) {

    // const contentDividerStyle = {
    //     background: Colors.CARD_PRIMARY_BORDER_COLOR,
    //     margin: 0,
    //     padding: 0,
    // }

    return (
        <Divider {...props} orientation="horizontal" variant={'middle'} flexItem style={contentDividerStyle}/>
    )
}

export const contentDividerStyle = {
    background: Colors.CARD_PRIMARY_BORDER_COLOR,
    margin: 0,
    padding: 0,
}