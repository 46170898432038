import firebase from "firebase";
import {
    Company,
    companyConverter,
    Group,
    groupConverter,
    InviteCode,
    inviteCodeConverter,
    User,
    userConverter
} from "./Models";
import {getCompanyDocumentReference, getGroupDocumentReference, getInviteCodeDocumentReference, getUserDocumentReference} from "./References";
import {Shift} from "../realtime/Models";

const firestore = firebase.firestore()

// https://firebase.google.com/docs/firestore/query-data/get-data
// https://firebase.google.com/docs/firestore/manage-data/add-data

export function getUser(userId: string, callback: (user: User | null) => void) {

    if (userId.length === 0) {
        return
    }
    const userReference = getUserDocumentReference(userId)

    userReference
        .withConverter(userConverter)
        .get()
        .then(doc => {
            if (doc.exists) {

                const user = doc.data()

                if (user !== undefined) {
                    callback(user)
                } else {
                    callback(null)
                }

            } else {
                callback(null)
            }
        })
}

export function getInviteCode(inviteCode : string, callback: (inviteCode: InviteCode | null) => void) {

    if (inviteCode.length === 0) {
        return
    }
    const inviteCodeRef = getInviteCodeDocumentReference(inviteCode)

    inviteCodeRef
        .withConverter(inviteCodeConverter)
        .get()
        .then(doc => {
            if (doc.exists) {

                const inviteCodeModel = doc.data()

                if (inviteCodeModel !== undefined) {
                    callback(inviteCodeModel)
                } else {
                    callback(null)
                }

            } else {
                callback(null)
            }
        })
}

export function getGroup(groupId : string, callback: (group: Group | null) => void) {

    if (groupId.length === 0) {
        return
    }
    const groupRef = getGroupDocumentReference(groupId)

    groupRef
        .withConverter(groupConverter)
        .get()
        .then(doc => {
            if (doc.exists) {

                const group = doc.data()

                if (group !== undefined) {
                    callback(group)
                } else {
                    callback(null)
                }

            } else {
                callback(null)
            }
        })
}


export function getCompany(companyId : string, callback: (company: Company | null) => void) {

    if (companyId.length === 0) {
        return
    }
    const groupRef = getCompanyDocumentReference(companyId)

    groupRef
        .withConverter(companyConverter)
        .get()
        .then(doc => {
            if (doc.exists) {

                const group = doc.data()

                if (group !== undefined) {
                    callback(group)
                } else {
                    callback(null)
                }

            } else {
                callback(null)
            }
        })
}


