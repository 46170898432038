

export abstract class ChildrenConstants {

    static readonly USERS = "users"
    static readonly USER_PERMISSIONS = "user_permissions"
    static readonly USER_LINKS = "user_links"
    static readonly USER_GROUPS = "user_groups"
    static readonly USER_COMPANIES = "user_companies"
    static readonly INVITE_CODES = "invite_codes"
    static readonly GROUPS = "groups"
    static readonly COMPANIES = "companies"

    // User permissions children
    static readonly USER_REQUESTS = "user_requests"

    // Group children
    static readonly MANAGERS = "managers"
    static readonly MEMBERS = "members"
    static readonly SIGNED_UP_MEMBERS = "signed_up_members"
    static readonly REMOVED_MEMBERS = "removed_members"
    static readonly MEMBER_REQUIREMENTS = "member_requirements"
    static readonly MEMBER_PLUGINS = "member_plugins"
    static readonly MEMBER_NOTIFICATIONS = "member_notifications"
    static readonly MEMBER_CONFIG_CHANGES = "member_config_changes"

}