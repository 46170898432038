import {makeStyles, Paper} from "@material-ui/core";
import {useContext, useEffect, useState} from "react";
import {getShifts, getUserPresence} from "../../firebase/realtime/Listeners";
import {shiftReference, shiftsReference, userPresenceReference} from "../../firebase/realtime/References";
import {AppDataContext} from "../../AppDataContext";
import {Shift as ShiftModel} from "../../firebase/realtime/Models";
import ShiftPanel from "../shift/ShiftPanel";
import {areShiftsPropsValid, ShiftsProps} from "../../props/AttendanceProps";
import Dimensions from "../../constants/Dimensions";
import {useOverviewStyles} from "../OverviewStyles";
import {List} from "@mui/material";
import ShiftItem from "./ShiftItem";

export default function AttendancePanel(props: ShiftsProps) {

    const styles = useStyles()
    const commonStyles = useOverviewStyles()

    const appData = useContext(AppDataContext)
    const [shifts, setShifts] = useState<Array<[string, ShiftModel]>>([])
    const user = appData.user
    const groupId = user?.groupIdPreference
    const firebaseUser = appData.firebaseUser
    const userId = firebaseUser?.uid

    // console.log("AttendancePanel props: " + JSON.stringify(props))

    useEffect(() => {

        if (userId == null || groupId == null) {
            return
        }
        if (!areShiftsPropsValid(props)) {
            console.log("Provided attendance props are not valid")
            return
        }

        // const currentDate = new Date()
        // const currentYear = currentDate.getFullYear()
        // const currentMonth = currentDate.getMonth()
        // const shiftsRef = shiftsReference(userId, groupId, currentYear, currentMonth)

        // const shiftsRef = shiftsReference('T3hebvjNUoVmvZkg59bjAEU75lv1', 'tzaCN6sbujQFTV7kgwO6', 2022, 1) : test

        getShifts(props, (shifts) => {
            if (shifts == null) {
                console.log("Retrieved shifts are null")
            }

            if (shifts != null) {
                setShifts(shifts)
            } else {
                setShifts([])
            }
        })
    }, [userId, groupId, props])

    console.log("Count of retrieved shifts " +  shifts.length)

    const listItems = shifts.map( (item: [string, ShiftModel], index: number) => {

        const [shiftKey, shift] = item

        return (

        <div>

            <ShiftItem shiftKey={shiftKey} shiftModel={shift} handleShiftOpen={handleOpenShift}/>
        </div>
    )});

    console.log("Count of list items " +  listItems.length)

    const handleOpenShift = (shiftKey : string) => {

        if (!areShiftsPropsValid(props)) {
            return
        }

        // TODO : Browser router?
        return (
            <ShiftPanel userId={props.userId} groupId={props.groupId} yearKey={props.yearKey} monthKey={props.monthKey} shiftKey={shiftKey}/>
        )
    }

    // if (shifts != null) {
    //     console.log("Retrieved shifts")
    //     for (let shift of shifts) {
    //         console.log(JSON.stringify(shift))
    //         console.log(shift.startTime)
    //     }
    // }

    // TODO : Shift model

    return (
        <div className={styles.root}>

            <h1>Attendance</h1>

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {[1, 2, 3].map((value) => (

                    listItems

                    // <ListItem
                    //     key={value}
                    //     disableGutters
                    //     secondaryAction={
                    //         <IconButton>
                    //             <CommentIcon />
                    //         </IconButton>
                    //     }
                    // >
                    //     <ListItemText primary={`Line item ${value}`} />
                    // </ListItem>
                ))}
            </List>


            {/*<h1>AttendancePanel</h1>*/}

            {/*<Paper className={styles.card} elevation={2}>*/}


            {/*</Paper>*/}


        </div>
    )
}


const useStyles = makeStyles({
    root: {
        padding: Dimensions.TAB_PANEL_PADDING
    },
    card: {
        height: '100%',
        width: '85%',
        marginTop: '20vw',
        marginBottom: '20vw'
    },
    title : {

    }
});