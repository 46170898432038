import React, {createRef, useContext, useEffect, useState} from "react";
import {userPresenceReference} from "../firebase/realtime/References";
import {getUserPresence} from "../firebase/realtime/Listeners";
import {makeStyles, Typography} from "@material-ui/core";
import Dimensions from "../constants/Dimensions";
import {getUser} from "../firebase/firestore/Listeners";
import {AppDataContext} from "../AppDataContext";
import {User} from "../firebase/firestore/Models";
import {Box, Chip, Stack} from "@mui/material";
import LoginTextField from "../login/LoginTextField";
import {LoginConstants} from "../login/LoginConstants";
import {HorizontalContentDivider} from "../ui/ContentDivider";
import Colors from "../constants/Colors";
import LogOutDialog from "../login/LogOutDialog";
import LogoutIcon from '@mui/icons-material/Logout';
import {ProfileConstants} from "./ProfileConstants";
import ProfileTextField from "./ProfileTextField";
import Constants from "../constants/Constants";
import RegistrationThankYou from "../login/RegistrationThankYou";

export default function Profile() {

    const styles = useStyles()

    const appData = useContext(AppDataContext)

    const firebaseUser = appData.firebaseUser
    const userId = firebaseUser?.uid
    const user = appData.user
    const creator = appData.groupCreator
    const group = appData.group
    const company = appData.groupCompany

    const [logOutDialogOpen, setLogOutDialogOpen] = useState(false)

    const profileBoxStyle = ({
        p: 2,
        borderRadius: '50px',
    });

    // user presence
    const [presence, setPresence] = useState("")
    useEffect(() => {

        if (userId == null) {
            return
        }

        getUserPresence(userId, (result => {
            // const value = JSON.stringify(snapshot.val())
            // console.log("Retrieved value: " + result)

            if (result != null) {
                setPresence(result.lastTimeActive)
            }
        }))
    }, [userId])

    if (Constants.USING_PROD_VERSION) {
        // TODO : Temp
        return (
            <RegistrationThankYou/>
        )
    }

    const handleVerifyEmail = () => {
        console.log("Email verification send")
        firebaseUser?.sendEmailVerification()
    }
    const handleChangePassword = () => {
        console.log("Change password called")
    }
    const handleSignOut = () => {
        console.log("Sign out clicked")
        setLogOutDialogOpen(true)
    }

    const closeLogOutDialog = () => {
        setLogOutDialogOpen(false)
    }

    const fullName = user?.fullName() != null ? user?.fullName() : ''
    const phoneNumber = user?.formattedPhoneNumber() ? user?.formattedPhoneNumber() : ''
    const email = user?.email != null ? user?.email : ''
    const address = user?.address != null ? user?.address : ''
    const workload = user?.workLoad != null ? user?.workLoad : ''
    const isVerified = firebaseUser != null ? firebaseUser.emailVerified : true
    const changePasswordOn = isVerified

    const groupName = group?.groupName != null ? group.groupName : 'Group name'
    const companyName = company?.companyName != null ? company?.companyName : 'Company name'
    const employerName = creator != null ? creator.fullName() : ''
    const employerEmail = creator?.email != null ? creator?.email : ''
    const employerPhoneNumber = creator?.phoneNumber != null ? creator.phoneNumber : ''

    // TODO : Employer data

    // tODO : use profile text field

    return (
        <div className={styles.root}>

            <h1 className={styles.profileTitle}>Profile</h1>

            {/*<p>User last active: {presence}</p>*/}

            <Box className={styles.profileBox} style={profileBoxStyle}>

                <h1 className={styles.fullName}>{fullName}</h1>

                <Stack spacing={2} direction={"column"} className={styles.profileStack}>

                    <LoginTextField

                        id={ProfileConstants.EMAIL_ID}
                        label="Email"
                        type="email"
                        value={email}
                        // error={invalidEmail}
                        // helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                        // onSelect={handleTextFieldSelect}
                        // onChange={handleTextFieldChange}
                        // onBlur={handleTextFieldBlur}
                        // inputRef={emailRef}
                    />

                    <LoginTextField

                        id={ProfileConstants.PHONE_NUMBER_ID}
                        label="Phone number"
                        type="text"
                        value={phoneNumber}
                        // error={invalidEmail}
                        // helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                        // onSelect={handleTextFieldSelect}
                        // onChange={handleTextFieldChange}
                        // onBlur={handleTextFieldBlur}
                        // inputRef={emailRef}
                    />

                    <LoginTextField

                        id={ProfileConstants.ADDRESS_ID}
                        label="Address"
                        type="text"
                        value={address}
                        // error={invalidEmail}
                        // helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                        // onSelect={handleTextFieldSelect}
                        // onChange={handleTextFieldChange}
                        // onBlur={handleTextFieldBlur}
                        // inputRef={emailRef}
                    />

                    <LoginTextField
                        id={ProfileConstants.WORKLOAD_ID}
                        label="Workload"
                        type="text"
                        value={workload}
                        // error={invalidEmail}
                        // helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                        // onSelect={handleTextFieldSelect}
                        // onChange={handleTextFieldChange}
                        // onBlur={handleTextFie ldBlur}
                        // inputRef={emailRef}
                    />

                    {/* Options */}
                    <Stack direction={'row'} spacing={2}>

                        <Chip label="Verify email" color={'primary'}
                              style={{backgroundColor: Colors.PRIMARY_TEXT_BLUE}}
                            // TODO : visibility isVerified
                              onClick={handleVerifyEmail}/>

                        <Chip label="Change password" color={'primary'}
                              style={{backgroundColor: Colors.PRIMARY_TEXT_BLUE}}
                              onClick={handleChangePassword}/>

                        <Chip label="Sign out" color={'primary'}
                              icon={<LogoutIcon fontSize={'small'}/>}
                              style={{
                                  backgroundColor: Colors.PRIMARY_TEXT_BLUE
                              }}
                              onClick={handleSignOut}
                        />

                    </Stack>

                    {/* User links */}
                    <Stack direction={'row'} spacing={2}>

                    </Stack>

                </Stack>

                <HorizontalContentDivider/>


                {/*Employer layout */}

                <Stack direction={'row'} spacing={1} padding={2}>

                    <h1 className={styles.groupName}>{groupName}</h1>

                    <h1 className={styles.companyName}>({companyName})</h1>

                </Stack>

                {/*TODO : Employer name */}

                <Stack spacing={2} direction={"column"} className={styles.profileStack}>

                    {/*TODO : User links, options, group name + (company), employer name, employer email, employer number */}

                    <LoginTextField

                        id={ProfileConstants.EMPLOYER_NAME_ID}
                        label="Employer name"
                        type="text"
                        value={employerName}
                        // error={invalidEmail}
                        // helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                        // onSelect={handleTextFieldSelect}
                        // onChange={handleTextFieldChange}
                        // onBlur={handleTextFieldBlur}
                        // inputRef={emailRef}
                    />

                    <LoginTextField

                        id={ProfileConstants.EMPLOYER_EMAIL_ID}
                        label="Employer email"
                        type="text"
                        value={employerEmail}
                        // error={invalidEmail}
                        // helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                        // onSelect={handleTextFieldSelect}
                        // onChange={handleTextFieldChange}
                        // onBlur={handleTextFieldBlur}
                        // inputRef={emailRef}
                    />

                    <LoginTextField

                        id={ProfileConstants.EMPLOYER_PHONE_NUMBER_ID}
                        label="Employer phone number"
                        type="text"
                        value={employerPhoneNumber}
                        // error={invalidEmail}
                        // helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                        // onSelect={handleTextFieldSelect}
                        // onChange={handleTextFieldChange}
                        // onBlur={handleTextFieldBlur}
                        // inputRef={emailRef}
                    />

                </Stack>

            </Box>

            <LogOutDialog handleCloseDialog={closeLogOutDialog} open={logOutDialogOpen}/>

        </div>
    )
}

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        // width: '100%',
        paddingTop: Dimensions.SECTION_PADDING_TOP,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '70%',
    },
    profileBox: {
        // display: "flex",
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        background: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
        border: `1px solid ${Colors.CARD_PRIMARY_BORDER_COLOR}`,
        padding: Dimensions.SECTION_PADDING_TOP,
        marginBottom: Dimensions.SECTION_PADDING_TOP,
    },
    profileTitle: {
        // textAlign: "left",
        // marginLeft: '15%',
        // marginBottom: 0,
        // marginRight: 0,
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,

        // width: '70%',
    },
    fullName: {
        color: Colors.PRIMARY_TEXT_COLOR,
        fontSize: Dimensions.SECTION_TITLE_MD_SIZE,
    },
    profileStack: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    optionsStack: {
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    groupName: {
        color: Colors.PRIMARY_TEXT_COLOR,
        fontSize: Dimensions.SECTION_TITLE_MD_SIZE,
        padding: 0,
        margin: 0,
    },
    companyName: {
        color: Colors.PRIMARY_TEXT_COLOR,
        fontSize: Dimensions.SECTION_TITLE_MD_SIZE,
        padding: 0,
        margin: 0,
    },
})