import firebase from "firebase";
import DocumentData = firebase.firestore.DocumentData;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import FirestoreDataConverter = firebase.firestore.FirestoreDataConverter;
import {firebaseConfig} from "../utils/FirebaseConfig";

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// https://firebase.google.com/docs/firestore/query-data/get-data#web-version-8_1

// TODO : Create shared directory

export interface UserSerializable {

    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    address: string,
    employer: boolean,
    iconId: string,
    workLoad: string,
    groupIdPreference: string,
    companyIdPreference: string,
    nightModePreference : boolean,
}

export class User implements UserSerializable {

    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    address: string
    employer: boolean
    iconId: string
    workLoad: string
    groupIdPreference: string
    companyIdPreference: string
    nightModePreference: boolean

    fullName = () => {
        return this.firstName + ' ' + this.lastName
    }

    formattedPhoneNumber: () => (string | null) = () => {
        if (this.phoneNumber == null || this.phoneNumber.length === 0) return null
        return "+" + this.phoneNumber.toString()
    }

    constructor(firstName: string,
                lastName: string,
                email: string,
                phoneNumber: string,
                address: string,
                employer: boolean,
                iconId: string,
                workLoad: string,
                groupIdPreference: string,
                companyIdPreference: string,
                nightModePreference : boolean) {

        this.firstName = firstName
        this.lastName = lastName
        this.email = email
        this.phoneNumber = phoneNumber
        this.address = address
        this.employer = employer
        this.iconId = iconId
        this.workLoad = workLoad
        this.groupIdPreference = groupIdPreference
        this.companyIdPreference = companyIdPreference
        this.nightModePreference = nightModePreference
    }
}

// Firestore data converter
export const userConverter: FirestoreDataConverter<User> = {
    toFirestore(user: User): DocumentData {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            address: user.address,
            employer: user.employer,
            iconId: user.iconId,
            workLoad: user.workLoad,
            groupIdPreference: user.groupIdPreference,
            companyIdPreference: user.companyIdPreference,
            nightModePreference: user.nightModePreference
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): User {
        const data = snapshot.data();
        return new User(data.firstName,
            data.lastName,
            data.email,
            data.phoneNumber,
            data.address,
            data.employer,
            data.iconId,
            data.workLoad,
            data.groupIdPreference,
            data.companyIdPreference,
            data.nightModePreference);
    },
}

export class Member {

    memberEmail: string
    memberId: string
    addedBy: string
    addedDateTime: string
    joinedDateTime: string
    invited: boolean

    constructor(memberEmail: string,
                memberId: string,
                addedBy: string,
                addedDateTime: string,
                joinedDateTime: string,
                invited: boolean) {

        this.memberEmail = memberEmail
        this.memberId = memberId
        this.addedBy = addedBy
        this.addedDateTime = addedDateTime
        this.joinedDateTime = joinedDateTime
        this.invited = invited
    }
}

export const memberConverter: FirestoreDataConverter<Member> = {
    toFirestore(member: Member): DocumentData {
        return {
            memberEmail: member.memberEmail,
            memberId: member.memberId,
            addedBy: member.addedBy,
            addedDateTime: member.addedDateTime,
            joinedDateTime: member.joinedDateTime,
            invited: member.invited,
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): Member {
        const data = snapshot.data();
        return new Member(data.memberEmail,
            data.memberId,
            data.addedBy,
            data.addedDateTime,
            data.joinedDateTime,
            data.invited);
    },
}

export class UserPermissions {
    userIds: Array<string>

    constructor(userIds: Array<string>) {
        this.userIds = userIds
    }
}

export const userPermissionsConverter: FirestoreDataConverter<UserPermissions> = {
    toFirestore(userPermissions: UserPermissions): DocumentData {
        return {
            userIds: userPermissions.userIds
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): UserPermissions {
        const data = snapshot.data();
        return new UserPermissions(data.userIds)
    },
}

export class UserGroups {

    userIds: Array<string>

    constructor(userIds: Array<string>) {
        this.userIds = userIds
    }
}

export const userGroupsConverter: FirestoreDataConverter<UserGroups> = {
    toFirestore(userGroups: UserGroups): DocumentData {
        return {
            userIds: userGroups.userIds
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): UserGroups {
        const data = snapshot.data();
        return new UserGroups(data.userIds)
    },
}

export class UserCompanies {
    companies: Array<string>

    constructor(companies: Array<string>) {
        this.companies = companies
    }
}

export const userCompaniesConverter: FirestoreDataConverter<UserCompanies> = {
    toFirestore(userCompanies: UserCompanies): DocumentData {
        return {
            companies: userCompanies.companies
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): UserCompanies {
        const data = snapshot.data();
        return new UserCompanies(data.companies)
    },
}

export class UserLinks {
    facebookLink: string
    instagramLink: string
    twitterLink: string
    linkedInLink: string
    gitHubLink: string
    personalWebsiteLink: string

    constructor(facebookLink: string,
                instagramLink: string,
                twitterLink: string,
                linkedInLink: string,
                gitHubLink: string,
                personalWebsiteLink: string) {
        this.facebookLink = facebookLink
        this.instagramLink = instagramLink
        this.twitterLink = twitterLink
        this.linkedInLink = linkedInLink
        this.gitHubLink = gitHubLink
        this.personalWebsiteLink = personalWebsiteLink
    }
}

export const userLinksConverter: FirestoreDataConverter<UserLinks> = {
    toFirestore(userLinks: UserLinks): DocumentData {
        return {
            facebookLink: userLinks.facebookLink,
            instagramLink: userLinks.instagramLink,
            twitterLink: userLinks.twitterLink,
            linkedInLink: userLinks.linkedInLink,
            gitHubLink: userLinks.gitHubLink,
            personalWebLink: userLinks.personalWebsiteLink
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): UserLinks {
        const data = snapshot.data();
        return new UserLinks(data.facebookLink,
            data.instagramLink,
            data.twitterLink,
            data.linkedInLink,
            data.gitHubLink,
            data.personalWebLink)
    },
}


export class InviteCode {

    groupId: string
    createdDateTime: string
    updatedDateTime: string

    constructor(groupId: string,
                createdDateTime: string,
                updatedDateTime: string) {
        this.groupId = groupId
        this.createdDateTime = createdDateTime
        this.updatedDateTime = updatedDateTime
    }
}

export const inviteCodeConverter: FirestoreDataConverter<InviteCode> = {
    toFirestore(inviteCode: InviteCode): DocumentData {
        return {
            groupId: inviteCode.groupId,
            createdDateTime: inviteCode.createdDateTime,
            updatedDateTime: inviteCode.updatedDateTime,
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): InviteCode {
        const data = snapshot.data();
        return new InviteCode(data.groupId,
            data.createdDateTime,
            data.updatedDateTime)
    },
}

export class Group {

    groupName: string
    groupDescription: string
    creatorId: string
    companyId: string
    inviteCode: string
    iconId: string
    createdDateTime: string

    constructor(groupName: string,
                groupDescription: string,
                creatorId: string,
                companyId: string,
                inviteCode: string,
                iconId: string,
                createdDateTime: string) {
        this.groupName = groupName
        this.groupDescription = groupDescription
        this.creatorId = creatorId
        this.companyId = companyId
        this.inviteCode = inviteCode
        this.iconId = iconId
        this.createdDateTime = createdDateTime
    }
}

export const groupConverter: FirestoreDataConverter<Group> = {
    toFirestore(group: Group): DocumentData {
        return {
            groupName: group.groupName,
            groupDescription: group.groupDescription,
            creatorId: group.creatorId,
            companyId: group.companyId,
            inviteCode: group.inviteCode,
            iconId: group.iconId,
            createdDateTime: group.createdDateTime
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): Group {
        const data = snapshot.data();
        return new Group(data.groupName,
            data.groupDescription,
            data.creatorId,
            data.companyId,
            data.inviteCode,
            data.iconId,
            data.createdDateTime)
    },
}


export class Company {

    companyName: string
    ownerId: string
    companyUrl: string
    iconId: string
    verified: boolean
    createdDateTime: string

    constructor(companyName: string,
                ownerId: string,
                companyUrl: string,
                iconId: string,
                verified: boolean,
                createdDateTime: string) {
        this.companyName = companyName
        this.ownerId = ownerId
        this.companyUrl = companyUrl
        this.iconId = iconId
        this.verified = verified
        this.createdDateTime = createdDateTime
    }
}

export const companyConverter: FirestoreDataConverter<Company> = {
    toFirestore(company: Company): DocumentData {
        return {
            companyName: company.companyName,
            ownerId: company.ownerId,
            companyUrl: company.companyUrl,
            iconId: company.iconId,
            verified: company.verified,
            createdDateTime: company.createdDateTime,
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): Company {
        const data = snapshot.data();
        return new Company(data.companyName,
            data.ownerId,
            data.companyUrl,
            data.iconId,
            data.verified,
            data.createdDateTime)
    },
}


export class GroupManager {
    canAddManagers: boolean
    canAddMembers: boolean
    addedDateTime: string

    constructor(canAddManagers: boolean,
                canAddMembers: boolean,
                addedDateTime: string) {
        this.canAddManagers = canAddManagers
        this.canAddMembers = canAddMembers
        this.addedDateTime = addedDateTime
    }
}

export const groupManagerConverter: FirestoreDataConverter<GroupManager> = {
    toFirestore(groupManager: GroupManager): FirebaseFirestore.DocumentData {
        return {
            canAddManagers: groupManager.canAddManagers,
            canAddMembers: groupManager.canAddMembers,
            addedDateTime: groupManager.addedDateTime
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): GroupManager {
        const data = snapshot.data();
        return new GroupManager(data.canAddManagers,
            data.canAddMembers,
            data.addedDateTime)
    },
}

export interface InviteCode {
    groupId : string,
    createdDateTime : string,
    updatedDateTime : string
}