import firebase from "firebase";
import {ChildrenConstants} from "./ChildrenConstants";

function groupReference(userId: string,
                        groupId: string) {
    return firebase.database().ref().child(ChildrenConstants.USERS)
        .child(userId).child(groupId)
}

export function shiftsReference(userId: string,
                                groupId: string,
                                yearKey: Number,
                                monthKey: Number) {
    return groupReference(userId, groupId)
        .child(ChildrenConstants.SHIFTS)
        .child(yearKey.toString())
        .child(monthKey.toString())
}

export function shiftReference(userId: string,
                               groupId: string,
                               yearKey: Number,
                               monthKey: Number,
                               shiftKey: string) {
    return shiftsReference(userId, groupId, yearKey, monthKey).child(shiftKey)
}

export function userPresenceReference(userId : string) {
    return firebase.database().ref().child(ChildrenConstants.USER_PRESENCE).child(userId)
}

export function awaitingUsersReference(firebaseApp? : firebase.app.App) {

    if (firebaseApp != null) {
        firebaseApp.database().ref().child(ChildrenConstants.APP_RELEASE_AWAITING)
    }

    return firebase.database().ref().child(ChildrenConstants.APP_RELEASE_AWAITING)
}





