import React, {useCallback, useEffect, useMemo, useState} from "react";
import firebase from "firebase";
import {AppDataContext} from "./AppDataContext";
import AppData from "./AppData";
import {firebaseConfig} from "./firebase/utils/FirebaseConfig";
import {getCompany, getGroup, getUser} from "./firebase/firestore/Listeners";
import {Company, Group, User} from "./firebase/firestore/Models";
import {UserPresence} from "./firebase/realtime/Models";
import {getUserPresence} from "./firebase/realtime/Listeners";

export const AppDataProvider: React.FC = ({children}) => {

    const [firebaseUser, setFirebaseUser] = useState<firebase.User | null | undefined>(undefined);
    const [user, setUser] = useState<User | null>(null)
    const [userPresence, setUserPresence] = useState<UserPresence | null>(null)
    const [group, setGroup] = useState<Group | null>(null)
    const [groupCreator, setGroupCreator] = useState<User | null>(null)
    const [groupCompany, setGroupCompany] = useState<Company | null>(null)

    // Initialize firebase
    let app: firebase.app.App = firebase.app()
    if (!firebase.apps.length) {
        app = firebase.initializeApp(firebaseConfig);
    }
    // console.log("Firebase app initialized")

    const auth = app.auth()

    // Listen for user changes
    useEffect(() => {
        auth.onAuthStateChanged((firebaseUser) => {
            // console.log("User auth state change")
            setFirebaseUser(firebaseUser);
        });
        // userCallback()
    }, [auth]);

    // TODO : Cache firebaseUser when page is changed

    // const userCallback = useCallback(() => {
    //     return auth.onAuthStateChanged((firebaseUser) => {
    //         console.log("User auth state change")
    //         setFirebaseUser(firebaseUser);
    //     });
    // }, [auth])

    // const firebaseUserMemo = React.useMemo(() => ({
    //     firebaseUser, setFirebaseUser
    // }), [firebaseUser]);

    const userId = firebaseUser?.uid

    useEffect(() => {
        if (userId == null) {
            return
        }
        getUser(userId, (user) => {
            if (user == null) {
                console.log("Retrieved user is null")
            }
            setUser(user)
        })
        getUserPresence(userId, (userPresence) => {
            setUserPresence(userPresence)
        })
    }, [auth, userId]);

    useEffect(() => {
        const groupId = user?.groupIdPreference
        if (userId == null || groupId == null) {
            return
        }
        getGroup(groupId, (group) => {
            if (user == null) {
                console.log("Retrieved user is null")
            }
            // console.log("Group retrieved")
            setGroup(group)
        })
    }, [auth, user, user?.groupIdPreference, userId]);

    useEffect(() => {
        const creatorId = group?.creatorId

        if (creatorId != null && creatorId !== 'undefined') {

            getUser(creatorId, (creator) => {
                if (creator == null) {
                    console.log("Retrieved group creator is null")
                }
                setGroupCreator(creator)
            })

            return
        }

        const companyId = group?.companyId
        if (companyId != null) {

            getCompany(companyId, (company => {
                if (company == null) {
                    console.log("Retrieved company is null")
                }

                setGroupCompany(company)
            }))
        }

    }, [auth, group?.companyId, group?.creatorId, user, userId]);

    const appData: AppData = ({
        firebaseApp: app,
        firebaseUser: firebaseUser,
        user: user,
        userPresence: userPresence,
        group: group,
        groupCreator: groupCreator,
        groupCompany: groupCompany,
    });

    return (
        <AppDataContext.Provider value={appData}>{children} </AppDataContext.Provider>
    )
};