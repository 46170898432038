export default abstract class Dimensions {


    static readonly PAGE_TITLE_TEXT_SIZE = "25px";
    // static readonly MOBILE_PAGE_TITLE_TEXT_SIZE = "40px";
    static readonly REDIRECT_SMALL_TEXT_SIZE = '12px';

    // Section (div)
    static readonly SECTION_TITLE_SIZE = '22px';
    static readonly SECTION_TITLE_MD_SIZE = '18px'
    static readonly SECTION_DESCRIPTION_SIZE = '20px';
    // static readonly MOBILE_SECTION_DESCRIPTION_SIZE = '25px';
    static readonly SECTION_DESCRIPTION_MD_SIZE = '16px'

    static readonly INFO_TITLE_TEXT_SIZE = '18px';
    static readonly INFO_TITLE_TEXT_SIZE_MD = '16px';

    static readonly SECTION_PADDING_TOP = '50px';
    static readonly SECTION_PADDING_TOP_HALF = '25px';
    static readonly SECTION_PADDING_TOP_DOUBLE = '100px';

    static readonly DIALOG_BORDER = "25px"
    static readonly DIALOG_PADDING = "15px"

    static readonly TAB_PANEL_PADDING = "25px"

    static readonly PAGE_WIDTH = "70%"
    static readonly MOBILE_PAGE_WIDTH = "85%"

}