import React from "react";
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import {AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import Colors from "../constants/Colors";
import NavigationPaths from "../constants/NavigationPaths";
import Dimensions from "../constants/Dimensions";
import Constants from "../constants/Constants";

// https://mui.com/components/accordion/

export default function Help() {

    const styles = useStyles()

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const accordionStyles = {
        accordion: {
            bgcolor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
            color: Colors.PRIMARY_TEXT_COLOR,

            width: '100%',
            padding: '10px',

            // borderBottom: '1px white'

            borderBottom: `1px solid ${Colors.CARD_PRIMARY_BORDER_COLOR}`,
            // '&:not(:last-child)': {
            //     borderBottom: 0,
            // },
            // '&:before': {
            //     display: 'none',
            // },
        },
        accordionTitle: {
            width: isMobile ? '70%' : '33%',
            flexShrink: 0,
        },
        accordionDescription: {
            color: Colors.SECONDARY_TEXT_COLOR,
            display: isMobile ? 'none' : 'visible',
        },
        accordionContent: {
            // backgroundColor: Colors.SECONDARY_BACKGROUND_COLOR,
            padding: isMobile ? '5px' : '15px',
            marginLeft: '15px',
            marginRight: '15px',
            color: Colors.SECONDARY_TEXT_COLOR,
        },
        expandIcon: {
            fill: 'white',
        },

    }

    return (
        <div className={styles.root}>

            <h1 className={styles.helpTitle}>Help</h1>

            <p className={styles.helpDescription}>Feel free to contact support, if you have any questions.</p>

            <div className={styles.accordionSection}>

                <Accordion expanded={expanded === 'panel1'}
                           sx={accordionStyles.accordion}
                    // className={styles.accordion}
                           onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={accordionStyles.expandIcon}/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={accordionStyles.accordionTitle}>
                            About Work Tracking
                        </Typography>
                        {/*<Typography sx={accordionStyles.accordionDescription}></Typography>*/}
                    </AccordionSummary>
                    <AccordionDetails
                        sx={accordionStyles.accordionContent}>
                        <AboutSection/>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'}
                           sx={accordionStyles.accordion}
                    // className={styles.accordion}
                           onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.expandIcon}/>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={accordionStyles.accordionTitle}>Organisations and groups</Typography>
                        <Typography sx={accordionStyles.accordionDescription}>
                            Manage access to your group
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.accordionContent}>
                        <GroupSection/>
                    </AccordionDetails>
                </Accordion>

                {/* AttendancePanel and shifts */}
                <Accordion expanded={expanded === 'panel3'}
                           sx={accordionStyles.accordion}
                    // className={styles.accordion}
                           onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.expandIcon}/>}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={accordionStyles.accordionTitle}>
                            Attendance and shifts
                        </Typography>
                        <Typography sx={accordionStyles.accordionDescription}>
                            Viewing details and statistics
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.accordionContent}>
                        <AttendanceSection/>
                    </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel4'}
                           sx={accordionStyles.accordion}
                    // className={styles.accordion}
                           onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.expandIcon}/>}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={accordionStyles.accordionTitle}>
                            Tasks
                        </Typography>
                        <Typography sx={accordionStyles.accordionDescription}>
                            Create tasks for yourself or for your group.
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.accordionContent}>
                        <TasksSection/>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'}
                           sx={accordionStyles.accordion}
                    // className={styles.accordion}
                           onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.expandIcon}/>}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <Typography sx={accordionStyles.accordionTitle}>
                            Profile
                        </Typography>
                        <Typography sx={accordionStyles.accordionDescription}>
                            Provided information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.accordionContent}>
                        <ProfileSection/>
                    </AccordionDetails>
                </Accordion>

                {/*TODO : Add settings*/}

                <Accordion expanded={expanded === 'panel6'}
                           sx={accordionStyles.accordion}
                    // className={styles.accordion}
                           onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.expandIcon}/>}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                    >
                        <Typography sx={accordionStyles.accordionTitle}>Suggest a feature</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.accordionContent}>
                        <SuggestFeatureSection/>
                    </AccordionDetails>
                </Accordion>
            </div>

        </div>
    )
}

function AboutSection() {

    const styles = aboutStyles()

    return (
        <div>
            <Typography>
                Learn more about Work Tracking in <a href={NavigationPaths.ABOUT}
                                                     className={styles.about}>About</a>.
            </Typography>
        </div>
    )
}

const aboutStyles = makeStyles(theme => ({
    about: {
        textAlign: "center",
        color: Colors.SECONDARY_TEXT_COLOR,
        textDecoration: 'none',
        fontWeight: 600,

        '&:hover': {
            color: Colors.PRIMARY_TEXT_BLUE
        },
    }
}));

function GroupSection() {

    // Group management

    // Add members

    // Edit members

    // Delete members

    // Manage access

    // User requests

    // Requirements and config

    return (
        <div>

            <Stack direction={'column'} spacing={2}>

                <Typography>
                    Groups are divided into managers and the members.
                    As a creator of the group, you'll gain access to add new managers, members and assign them specific
                    privileges.
                    It is also possible to connect your own company to the group.
                </Typography>

                <Typography>
                    By default, group called DefaultGroup is created when you create new account with given unique
                    invite code.
                    This invite code can be used by members to join your group, but before you do that, you need to add
                    them as your member by providing their email,
                    the same email they must sign up with, in order to join you.
                </Typography>

                <Typography>
                    It is possible to restrict the users that you've added and set them specific requirements or restrictions, such as require them
                    to have their location or notifications turned on. If they don't do so, their shift is marked as invalid.
                </Typography>

                <Typography>
                    Managers with access to add new members are also permitted to switch their role to member and
                    also enable existing members to switch their role to manager.
                </Typography>

            </Stack>
        </div>
    )
}

function AttendanceSection() {

    return (
        <div>

            <Stack direction={'column'} spacing={2}>

                {/*https://github.com/nellsam/work_tracking_android/blob/master/androidApp/src/main/java/com/worktrackingapp/work_tracking/utils/ShiftConstraints.kt*/}

                <Typography>
                    New shift is created each time that you start tracking as an employee.
                    You can edit this shift, by changing the duration, adding new pauses, setting note or adding images.
                    In case you have location turned on, this location is used to display route on the map, when you
                    open the shift.
                    This includes location during your work period, start and stop of the pause (note that location is
                    not retrieved, when you're currently having a pause).

                </Typography>

                <Typography>
                    By default, following constraints are being applied by default, unless your manager of the group
                    assigned you a different ones:
                </Typography>

                <ul>
                    <li>
                        <Typography>
                            Maximum shift duration is set by default to 48 hours
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            Minimum shift duration is set by default to 5 minutes.
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            Maximum pause duration is set to 24 hours.
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            Minimum pause duration is 5 minutes.
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            Maximum inactive duration that you can have while your shift is in progress is 12 hours.
                        </Typography>
                    </li>
                </ul>

                <Typography>
                    It is possible to restrict the users you've added and set them specific requirements such as
                    requirement to have their location or notifications turned on.
                    All the shifts that doesn't match these default constraints or the ones specified by your employer
                    are marked as invalid.
                    If the shift has still a valid start and end point, it still remains counted in month total.
                </Typography>

                <Typography>
                    For the best user experience, we advise you to have your location turned on during shifts, even if
                    your employer doesn't require it.
                    For now, the location is being retrieved every 15 minutes. You can turn on your location in settings.
                </Typography>

            </Stack>


        </div>
    )
}

function LocationSection() {

    return (
        <div>
            <Typography>
                For the best user experience we advise you to have location always turned on.
                You can turn location on/off in settings.

                Location is being retrieved every 15 minutes however you will be able to change this interval in near
                future.

            </Typography>
        </div>
    )
}

function TasksSection() {

    return (
        <div>
            <Stack direction={'column'} spacing={2}>

                {/*https://github.com/nellsam/work_tracking_android/blob/master/androidApp/src/main/java/com/worktrackingapp/work_tracking/utils/ShiftConstraints.kt*/}

                <Typography>
                    Assign to yourself or the other members tasks that need to be done. Each task can be set a title, description, time it needs
                    to be done, own priority or it can consist of group of subtasks that can be done one by one and mark them as
                    finished.
                </Typography>

            </Stack>
        </div>
    )
}

function ProfileSection() {

    return (
        <div>

            <Stack direction={'column'} spacing={2}>

                {/*https://github.com/nellsam/work_tracking_android/blob/master/androidApp/src/main/java/com/worktrackingapp/work_tracking/utils/ShiftConstraints.kt*/}
                {/* 30.3. 2022 Starbucks */}

                <Typography>
                    Except from the name and email that we require you to have fulfilled, you can optionally provide phone
                    number that can your employer use to call you directly,
                    your living address or workload that describes your job position. Specifying your address can also
                    notify you and prevent from forgetting to stop your shift.
                </Typography>

                <Typography>
                    Your profile information is reused across groups you're in, but since each group member has it's own profile,
                    it's up to you to edit this information specifically for your position in each group.
                    Provided personal details are only visible to managers of the group and only you can edit them.
                </Typography>

                <Typography>
                    For the best user experience we advise you to fill all the information.
                </Typography>

            </Stack>

        </div>
    )
}

function SuggestFeatureSection() {

    const styles = makeStyles(theme => ({
        supportText: {
            textDecoration: "none",
            color: Colors.SECONDARY_TEXT_COLOR,
            fontWeight: 'bolder',
            '&:hover': {
                color: 'dodgerblue'
            },
        }
    }))();

    const supportText = (
        <a
            className={styles.supportText}
            href={'mailto: ' + Constants.SUPPORT_EMAIL}
            target={'_blank'}
            rel={'noopener noreferrer'}
        >
            support
        </a>
    )

    return (
        <div>
            <Stack direction={'column'} spacing={2}>

                {/*https://github.com/nellsam/work_tracking_android/blob/master/androidApp/src/main/java/com/worktrackingapp/work_tracking/utils/ShiftConstraints.kt*/}

                <Typography>
                    Any ideas or your own needs are very welcome and we'd be glad to help. For now, please contact our {supportText}.
                </Typography>

            </Stack>
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        // width: '100%',
        paddingTop: Dimensions.SECTION_PADDING_TOP,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: Dimensions.PAGE_WIDTH,

        [theme.breakpoints.down('sm')]: {
            width: Dimensions.MOBILE_PAGE_WIDTH,
            paddingTop: Dimensions.SECTION_PADDING_TOP_HALF,
        },
    },
    helpTitle: {
        // textAlign: "left",
        // marginLeft: '15%',
        marginBottom: 0,
        marginRight: 0,
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,

        // width: '70%',
    },
    helpDescription: {
        // padding: '20px',
        // marginLeft: '15%',
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.SECONDARY_TEXT_COLOR,
    },
    accordionSection: {

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',

        marginTop: '25px',
        marginBottom: '25px',
        // width: '100%',
    },
    accordion: {
        // background: Colors.PRIMARY_BACKGROUND_COLOR,
        // width: '1000px',
        // width: '70%',
        // padding: '10px',

        // bgcolor: Colors.PRIMARY_BACKGROUND_COLOR,
        // color: Colors.PRIMARY_TEXT_COLOR,

        // ['sx']: {
        //     bgcolor: Colors.PRIMARY_BACKGROUND_COLOR,
        //     color: Colors.PRIMARY_TEXT_COLOR,
        // }
    },
}));
