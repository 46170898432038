import firebase from "firebase/app";
import {Company, Group, User} from "./firebase/firestore/Models";
import {UserPresence} from "./firebase/realtime/Models";


export default interface AppData {
    firebaseApp: firebase.app.App | null,
    firebaseUser: firebase.User | null | undefined,
    user: User | null,
    group: Group | null,
    userPresence: UserPresence | null,
    groupCreator: User | null,
    groupCompany: Company | null,
}

export const initialAppDataState: AppData = ({
    firebaseApp: null,
    firebaseUser: undefined,
    user: null,
    group: null,
    userPresence: null,
    groupCreator: null,
    groupCompany: null,
});