import {makeStyles} from "@material-ui/core";
import Dimensions from "../constants/Dimensions";
import React from "react";
import Colors from "../constants/Colors";
import {Stack} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';

export default function RegistrationThankYou() {

    const styles = useStyles()

    return (
        <div className={styles.root}>

            {/*<Stack direction={"row"} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle'}}>*/}

            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>

                <h1 className={styles.title}>Thank you for registration</h1>
                <DoneIcon sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle'}}/>

            </div>

            <p className={styles.titleDescription}>
                Content is being prepared and will be available soon.
            </p>

        </div>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        // width: '100%',
        paddingTop: Dimensions.SECTION_PADDING_TOP,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '70%',
    },
    title: {
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,
        marginRight: '10px'
    },
    titleDescription : {
        fontSize: Dimensions.SECTION_DESCRIPTION_SIZE,
        color: Colors.SECONDARY_TEXT_COLOR,
        // margin: 0,
        // padding: 0,
    }
}))