import {makeStyles} from "@material-ui/core";
import NavigationPaths from "./constants/NavigationPaths";
import Constants from "./constants/Constants";
import Dimensions from "./constants/Dimensions";
import Colors from "./constants/Colors";

export const FOOTER_HEIGHT = '150px'

export default function Footer() {

    const styles = useStyles()

    return (

        <footer className={styles.root}>

            <div className={styles.support}>

                <a className={styles.supportText}>Support</a>
                <a className={styles.supportEmail}
                   href={'mailto: ' + Constants.SUPPORT_EMAIL}
                   target={'_blank'}
                   rel={'noopener noreferrer'}
                >
                    {Constants.SUPPORT_EMAIL}
                </a>

            </div>

            <div className={styles.bottomMenu}>

                <a className={styles.bottomMenuItem} href={NavigationPaths.TERMS_OF_USE}>Terms of use</a>

                <a className={styles.bottomMenuItem} href={NavigationPaths.PRIVACY_POLICY}>Privacy policy</a>

                <a className={styles.bottomMenuItem} href={NavigationPaths.INVALID_PAGE}>Cookies</a>

            </div>

        </footer>
    )
}

const useStyles = makeStyles(theme => ({

    // Make it appear at bottom
    root: {
        position: 'relative',
        clear: 'both',
        bottom: 0,
        width: '100%',
        height: FOOTER_HEIGHT,
        background: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'stretch',

        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            paddingTop: '30px',
            paddingBottom: '10px',
        },
    },
    support: {
        display: 'flex',
        flexDirection: 'column',
        // left ?
        // alignItems: 'start',
        alignItems: 'center',
        justifyContent: 'center',
        // margin: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: '20px',
    },
    supportText: {
        fontSize: '14px',
        color: 'gray',
        fontWeight: 'bold',
    },
    supportEmail: {
        fontSize: '16px',
        color: Colors.SECONDARY_TEXT_COLOR,
        fontWeight: 500,
        textDecoration: "none",
        wordBreak: 'keep-all',
        overflow: 'auto',

        '&:hover': {
            color: 'dodgerblue'
        },
    },
    bottomMenu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',

        // Bottom
        // alignSelf: 'flex-end',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    bottomMenuItem: {
        textDecoration: 'none',
        wordBreak: 'keep-all',
        overflow: 'auto',
        marginRight: '15px',
        marginLeft: '15px',
        paddingTop: '15px',
        paddingBottom: '15px',

        fontSize: Dimensions.REDIRECT_SMALL_TEXT_SIZE,
        color: Colors.REDIRECT_SMALL_TEXT_COLOR,

        '&:hover': {
            color: Colors.REDIRECT_SMALL_TEXT_HOVER_COLOR,
        },
    },
}));
