

export interface GradientState {
    gradientState: GradientDegree,
    previousGradientState: GradientDegree,
    // gradientState2: GradientDegree,
    // previousGradientState2: GradientDegree,
    milliseconds: number,
    gradientTimerActive : boolean,
    gradientIdle : boolean,
}

export enum GradientDegree {
    DEG_0 = 0,
    DEG_45 = 45,
    DEG_90 = 90,
    DEG_135 = 135,
    DEG_180 = 180,
    DEG_225 = 225,
    DEG_270 = 270,
    DEG_315 = 315,
}

export const initialGradientDegreeState = () => {
    return ({
        gradientState: GradientDegree.DEG_90,
        previousGradientState: getRandomGradientDegree(0),
        // gradientState2: getRandomGradientDegree(0),
        // previousGradientState2: getRandomGradientDegree(0),
        milliseconds: 0,
        // TODO : Disable for mobile devices
        gradientTimerActive: true,
        gradientIdle: false,
    })
}

export function getNewGradientState(state: GradientState) {
    // console.log("Get new state called")
    return ({
        ...state,
        gradientState: getRandomGradientDegree(state.gradientState.valueOf()),
        previousGradientState: state.gradientState,
        // gradientState2: getRandomGradientDegree(state.gradientState2.valueOf()),
        // previousGradientState2: state.previousGradientState2,
        milliseconds: 0,
        gradientTimerActive: state.gradientTimerActive,
        gradientIdle: state.gradientIdle,
    })
}


function getRandomGradientDegree(except: number): GradientDegree {

    const min = 1
    const max = 8

    let random = Math.floor(Math.random() * (max - min + 1)) + min
    // while (random !== except) {
    //     random = Math.floor(Math.random() * (max - min + 1)) + min
    // }

    switch (random) {
        case 1 : {
            return GradientDegree.DEG_0
        }
        case 2 : {
            return GradientDegree.DEG_45
        }
        case 3 : {
            return GradientDegree.DEG_90
        }
        case 4 : {
            return GradientDegree.DEG_135
        }
        case 5 : {
            return GradientDegree.DEG_180
        }
        case 6 : {
            return GradientDegree.DEG_225
        }
        case 7 : {
            return GradientDegree.DEG_270
        }
        case 8 : {
            return GradientDegree.DEG_315
        }
        default : {
            return GradientDegree.DEG_90
        }
    }
}