import {isEmailValid} from "../login/AuthValidation";
import {awaitingUsersReference} from "../firebase/realtime/References";
import firebase from "firebase";
import {AddAwaitingUserRequestData} from "../firebase/functions/RequestData";
import {addAwaitingUser as addAwaitingUserFunction} from "../firebase/functions/CloudFunctions"

// https://github.com/nellsam/work_tracking_web/blob/initial/src/main/kotlin/AwaitingAppRelease.kt

// export function addAwaitingUser(email: string, callback: (successful: boolean) => void) {
//
//     if (!isEmailValid(email)) {
//         callback(false)
//         return
//     }
//
//     const requestData : AddAwaitingUserRequestData = ({
//         email: email
//     })
//     addAwaitingUserFunction(requestData, (success => {
//         callback(success)
//     }));
// }

export function addAwaitingUser(email: string, callback: (successful: boolean) => void) {

    if (!isEmailValid(email)) {
        callback(false)
        return
    }

    // Get reference to awaiting users
    const awaitingUsersRef = awaitingUsersReference()

    // Get unique key for new user
    const uniqueKey = awaitingUsersRef.push().key
    if (uniqueKey == null) {
        callback(false)
        return;
    }

    // Get current time
    const timestamp = new Date().toISOString()

    // Create new awaiting user
    // const awaitingUser = AwaitingUser(email, timestamp)

    // Set new awaiting user
    awaitingUsersRef.child(uniqueKey).set({
        "email": email,
        "timestamp": timestamp,
    }).then(() => {
        callback(true)
    }).catch((error) => {
        callback(false)
    });

}