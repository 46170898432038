


export interface ShiftsProps {
    userId: string,
    groupId: string,
    yearKey: number,
    monthKey: number,
}

export function areShiftsPropsValid(props: ShiftsProps): boolean {
    if (props.userId.length === 0) {
        return false
    }
    if (props.groupId.length === 0) {
        return false
    }
    if (props.yearKey === 0) {
        return false
    }
    if (props.monthKey === 0) {
        return false
    }
    return true
}

export interface ShiftProps extends ShiftsProps {
    shiftKey : string
}

export function areShiftPropsValid(props: ShiftProps): boolean {
    if (props.userId.length === 0) {
        return false
    }
    if (props.groupId.length === 0) {
        return false
    }
    if (props.yearKey === 0) {
        return false
    }
    if (props.monthKey === 0) {
        return false
    }
    if (props.shiftKey.length === 0) {
        return false
    }
    return true
}
