import {makeStyles} from "@material-ui/core";
import Colors from "../constants/Colors";
import Dimensions from "../constants/Dimensions";


export const dialogPaperProps = {
    style: {
        backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
        padding: Dimensions.DIALOG_PADDING,
        borderRadius: Dimensions.DIALOG_BORDER,
    },
}