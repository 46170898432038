import {Alert, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Box, useMediaQuery, useTheme} from "@material-ui/core";
import React, {useEffect} from "react";
import Dimensions from "../constants/Dimensions";


export default function FacebookUnavailableAlert(props: FacebookAlertProps) {

    useEffect(() => {
        setInterval(() => {
            props.handleAlertClose()
        }, 5000)
    })

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (

        <Box sx={{width: isMobile ? '85%' : '25%'}}>
            <Collapse in={props.alertOpened}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={props.handleAlertClose}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                    severity={"info"}
                    sx={{
                        mb: 2,
                        marginTop: Dimensions.SECTION_PADDING_TOP
                    }}
                >
                    Facebook login is currently not available. Please try again later.
                </Alert>
            </Collapse>
        </Box>
    )
}


interface FacebookAlertProps {
    alertOpened: boolean
    handleAlertClose: () => void
}