

export function splitFullName(fullName: string): Name {

    const splitName = fullName.split(' ')

    let firstName = ''
    let lastName = ''

    if (splitName.length === 1) {

        // Last part
        firstName = splitName[0]

    } else if (splitName.length === 2) {

        firstName = splitName[0]
        lastName = splitName[1]

    } else if (splitName.length > 2) {

        for (let i = 0; i < splitName.length - 1; i++) {

            const part = splitName[i]

            if (firstName.length > 0) {
                // Concat part
                firstName = firstName + " " + part
            } else {
                firstName = part
            }

            // Last index = last name
            if (i === (splitName.length - 1)) {
                lastName = part
            }
        }
    }

    return {
        firstName: firstName,
        lastName: lastName,
    }
}

interface Name {
    firstName: string,
    lastName: string,
}