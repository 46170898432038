import {makeStyles} from "@material-ui/core";
import Colors from "../constants/Colors";
import Dimensions from "../constants/Dimensions";

export const useLoginStyles = makeStyles(theme => ({
    root: {

        // TODO : Try this color
        // backgroundColor: 'whitesmoke',

        // 100% view - navigation and footer height
        minHeight: `calc(100vh - (70px + 150px))`,
        // position: 'relative',

        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        // backgroundColor: Colors.PRIMARY_BACKGROUND_COLOR,
        // background: 'linear-gradient(180deg, rgba(2,0,36,0) 95%, rgba(30,136,229,0.10407913165266103) 100%)',
        // background: 'linear-gradient(0deg, rgba(0,0,0,1) 25%, rgba(33,33,33,1) 100%)',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        // Make it appear in center vertically
        // position: "absolute",
        // top: '50%',
        // transform: 'translateY(-50%)',
    },

    loginTitle: {
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,
        fontWeight: 600,
        marginTop: '25px',
        padding: 0,
    },
    // textFieldPaper : {
    //     background: Colors.CARD_BLACK_DARK_GRAY,
    //     padding: '25px',
    //     textAlign: "center",
    // },
    textFieldBox: {
        textAlign: "center",
        marginTop: '10px',
    },
    textFieldStack: {
        // marginBottom: '50px',
        // width: '1000px',
        // fontSize: '15px',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // textAlign: 'center',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkbox: {},
    signUpButton: {
        backgroundColor: 'black'
    },
    facebookButton: {
        // backgroundColor: '#1565C0'

        // android     <color name="md_blue_800">#1565C0</color>
    },
    authRedirectItem: {
        textDecoration: 'none',
        padding: '5px',
        margin: '25px'
    },
    authRedirectText: {

        fontSize: Dimensions.REDIRECT_SMALL_TEXT_SIZE,
        color: Colors.REDIRECT_SMALL_TEXT_COLOR,

        '&:hover': {
            color: Colors.REDIRECT_SMALL_TEXT_HOVER_COLOR,
        },
    },
}));

export const authButtonStyle = {
    // paddingTop: '10px',
    // paddingBottom: '10px',
    padding: '7px',
    borderRadius: 2,

    backgroundColor: 'black',
    ":hover": {
        backgroundColor: Colors.CARD_PRIMARY_BACKGROUND_COLOR,
        // elevation: '4px',
    }
}

export const facebookButtonStyle = {
    // paddingTop: '10px',
    // paddingBottom: '10px',
    padding: '7px',
    borderRadius: 2,
    backgroundColor: '#1565C0',
    ":hover": {
        backgroundColor: 'dodgerblue',
    }
}
