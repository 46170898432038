

export default abstract class Constants {

    static readonly SUPPORT_EMAIL = "support@worktrackingapp.com"
    static readonly RECAPTCHA_KEY = "6LcHQm0aAAAAAC_9x6ztYnjJuhRcYIvG_6j83VbR"

    static readonly INVITE_CODE_MIN_LENGTH = 5
    static readonly INVITE_CODE_MAX_LENGTH = 20

    static readonly USING_PROD_VERSION = true

}