import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {DialogProps} from "@mui/material/Dialog/Dialog";
import Colors from "../constants/Colors";
import LoginTextField from "./LoginTextField";
import {useState} from "react";
import {LoginConstants} from "./LoginConstants";
import {dialogPaperProps} from "../ui/MUIStyles";
import {getInviteCode} from "../firebase/firestore/Listeners";
import {Member} from "../firebase/firestore/Models";
import {getMembersCollectionReference} from "../firebase/firestore/References";
import firebase from "firebase";
import {firebaseConfig} from "../firebase/utils/FirebaseConfig";
import {useMediaQuery, useTheme} from "@material-ui/core";

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default function EmployeeDialog(props: EmployeeDialogProps) {

    const [inviteCode, setInviteCode] = useState('')
    const [notExistingInviteCode, setNotExistingInviteCode] = useState(false)
    const [notMember, setNotMember] = useState(false)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const [inviteCodeBlurred, setInviteCodeBlurred] = useState(false)
    // const [dialogSubmitted, setDialogSubmitted] = useState(false)

    const handleClose = () => {
        props.handleCloseDialog()
    }

    const handleInviteCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target
        // console.log("invite code changed with " + value)

        if (notExistingInviteCode) {
            setNotExistingInviteCode(false)
        }

        if (notMember) {
            setNotMember(false)
        }

        setInviteCode(value)
    }

    // const handleInviteCodeBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    //     // console.log("Invite code blurred")
    //     setInviteCodeBlurred(true)
    // }

    const handleContinue = () => {
        // console.log("Continue")

        // setDialogSubmitted(true)
        //
        // if (!isInviteCodeValid(inviteCode)) {
        //     console.log("Invite code is not valid")
        //     return
        // }

        getInviteCode(inviteCode, (inviteCodeModel => {

            if (inviteCodeModel === null) {
                setNotExistingInviteCode(true)
                return
            }

            const groupId = inviteCodeModel.groupId

            findMemberWithMatchingEmail(groupId, props.email, (memberKey => {

                if (memberKey === null || memberKey.length === 0) {

                    // Not member
                    setNotMember(true)

                    return
                }

                // Return result
                props.handleDialogResult(groupId)
            }))
        }))
    }

    const invalidInviteCode = notExistingInviteCode || notMember
    let inviteCodeHelperText = ''
    if (notExistingInviteCode) {
        inviteCodeHelperText = LoginConstants.NOT_EXISTING_INVITE_CODE
    } else if (notMember) {
        inviteCodeHelperText = LoginConstants.NOT_MEMBER_GROUP
    }

    // Separate custom props from DialogProps
    const {handleCloseDialog, handleDialogResult, ...rest} = props

    return (
        <div>
            <Dialog {...rest}
                    onClose={handleClose}
                    sx={{}}
                    PaperProps={dialogPaperProps}
            >
                <DialogTitle
                    sx={{
                        color: Colors.PRIMARY_TEXT_COLOR,
                    }}
                >Enter invite code</DialogTitle>

                <DialogContent>

                    <LoginTextField
                        autoFocus={true}
                        label={'Invite code'}
                        // fullWidth
                        style={{
                            marginTop: '10px',
                            width: isMobile ? '80%' : 'inherit'
                        }}
                        value={inviteCode}
                        onChange={handleInviteCodeChange}
                        error={invalidInviteCode}
                        helperText={inviteCodeHelperText}
                        // error={invalidInviteCode}
                        // helperText={invalidInviteCode ? LoginConstants.INVALID_INVITE_CODE : ''}
                        // onBlur={handleInviteCodeBlur}
                    />

                    <DialogContentText
                        sx={{
                            color: Colors.SECONDARY_TEXT_COLOR,
                            marginTop: isMobile ? '10px' : '50px',
                            padding: isMobile ? '10px' : '0px',
                        }}>
                        This invite code is used to identify your organisation.
                        If you haven't received any invite code, please contact your employer.
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleContinue}>Continue</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function findMemberWithMatchingEmail(groupId: string,
                                     email: string,
                                     callback: (memberKey: string | null) => void) {

    const groupMembersCollectionRef = getMembersCollectionReference(groupId)

    // console.log("path " + groupMembersCollectionRef.path)

    // https://firebase.google.com/docs/firestore/query-data/get-data
    groupMembersCollectionRef
        .where("memberEmail", "==", email)
        .get()
        .then((querySnapshot: any) => {

            querySnapshot.forEach((doc: any) => {

                // console.log(JSON.stringify(doc.data()))

                const member: Member = doc.data()

                // console.log("Comparing email " + email + " with member email " + member.memberEmail)
                if (member.memberEmail === email) {
                    const memberKey = doc.id;
                    callback(memberKey)
                    return
                }
            });

            callback(null)

        }).catch((error: any) => {
        console.log("Error getting document:", error);
    });
}


interface EmployeeDialogProps extends DialogProps {

    email : string,
    handleCloseDialog: () => void,
    handleDialogResult: (groupId: string) => void,

}