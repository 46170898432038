import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Colors from "../constants/Colors";
import Dimensions from "../constants/Dimensions";
import AttendancePanel from "./attendance/AttendancePanel";
import TasksPanel from "./tasks/TasksPanel";
import {BrowserRouter, Link} from "react-router-dom";
import {Route, Switch} from "react-router";
import {AppDataContext} from "../AppDataContext";
import UnavailablePanel from "./UnavailablePanel";
import PluginsPanel from "./plugins/PluginsPanel";
import {
    setUserPresence,
} from "../firebase/realtime/Updaters";
import OverviewPanel from "./stats/OverviewPanel";
import RegistrationThankYou from "../login/RegistrationThankYou";
import Constants from "../constants/Constants";

// https://mui.com/components/menus/
// https://mui.com/components/tabs/
// https://stackoverflow.com/a/51229652/12580605

export default function Overview() {

    const styles = useStyles()

    const appData = useContext(AppDataContext)
    const userId = appData.firebaseUser?.uid

    const [tabSelected, setTabSelected] = useState(TabTypes.STATISTICS)

    const handleTabChange = (tabType: TabTypes) => {
        setTabSelected(tabType)
    }

    const title = getTitleForTab(tabSelected)
    console.log("Tab " + tabSelected + " changed with title " + title)

    useEffect(() => {

        const currentUserPresence = appData.userPresence
        if (userId == null || currentUserPresence == null) {
            return
        }

        const currentTime = new Date().toISOString()

        const userPresence = {
            lastTimeActive: currentTime,
            lastTimeSignedIn: currentUserPresence?.lastTimeSignedIn,
        }

        setUserPresence(userId, userPresence, (success => {
            if (success) {
                console.log("Last time active update successfully")
            } else {
                console.log("Failed to update last time active")
            }
        }))
    })

    if (Constants.USING_PROD_VERSION) {
        return (
            <RegistrationThankYou/>
        )
    }

    return (

        <div className={styles.root}>

            <div className={styles.tabSection}>

                <h1 className={styles.title}>{title}</h1>

                <OverviewTabs onTabChanged={handleTabChange}/>

            </div>

        </div>
    )
}

enum TabTypes {
    STATISTICS,
    ATTENDANCE,
    TASKS,
    PLUGINS,
    GROUPS,
    UNDEFINED,
    // TODO : Shift, other plugins if enabled
}

function getTitleForTab(tab: TabTypes) {
    switch (tab) {
        case TabTypes.STATISTICS: {
            return "Overview"
        }
        case TabTypes.ATTENDANCE : {
            return "Attendance"
        }
        case TabTypes.TASKS: {
            return "Tasks"
        }
        case TabTypes.PLUGINS : {
            return "Plugins"
        }
        case TabTypes.GROUPS : {
            return "Groups"
        }
        case TabTypes.UNDEFINED : {
            return ""
        }
    }
}

function getLabelForTab(tab: TabTypes) {
    const labelName = getTitleForTab(tab)

    return (
        <span style={{color: Colors.PRIMARY_TEXT_COLOR}}> {labelName}</span>
    )
}

function getPathForTab(tab: TabTypes) {
    switch (tab) {
        case TabTypes.STATISTICS: {
            return "/overview"
        }
        case TabTypes.ATTENDANCE : {
            return "/attendance"
        }
        case TabTypes.TASKS: {
            return "/tasks"
        }
        case TabTypes.PLUGINS : {
            return "/plugins"
        }
        case TabTypes.GROUPS : {
            return "/groups"
        }
        case TabTypes.UNDEFINED : {
            return "/undefined"
        }
    }
}

// function TabPanel(props: TabPanelProps) {
//     const {children, value, index, ...other} = props;
//
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`vertical-tabpanel-${index}`}
//             aria-labelledby={`vertical-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{p: 3}}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function OverviewTabs(props: OverviewTabsProps) {

    const [value, setValue] = React.useState(0);

    const appData = useContext(AppDataContext)
    const user = appData.user

    const isEmployer = user?.employer ? user?.employer : false

    const styles = useStyles()

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {

        let selectedType: TabTypes = newValue

        props.onTabChanged(selectedType)

        setValue(newValue);
    };

    // Change tabs colors

    const tabsBackgroundBoxStyle = {
        p: 2,
        borderRadius: '50px',
    }

    const tabVisible = (type: TabTypes) => {

        if (isEmployer) {


        } else {
            return type !== TabTypes.GROUPS
        }

        return true
    }

    const attendancePanel = () => {

        const userId = appData.firebaseUser?.uid
        const groupId = appData.user?.groupIdPreference
        const currentDate = new Date()
        const yearKey = currentDate.getFullYear()
        // const monthKey = currentDate.getMonth()
        const monthKey = 3

        if (userId == null || groupId == null) {
            return (<UnavailablePanel/>)
        }

        return (<AttendancePanel userId={userId} groupId={groupId} yearKey={yearKey} monthKey={monthKey}/>)
    }

    return (

        <BrowserRouter>
            <Box
                className={styles.tabsBackgroundBox}
                sx={{flexGrow: 1, display: 'flex', flexDirection: 'row', height: '80%'}}
                style={tabsBackgroundBoxStyle}
            >
                <Tabs
                    orientation="vertical"
                    variant="fullWidth"
                    value={value}
                    defaultValue={1}
                    onChange={handleTabChange}
                    // aria-label="Vertical tabs example"
                    sx={{borderRight: 1, borderColor: 'divider'}}
                >
                    <Tab label={getLabelForTab(TabTypes.STATISTICS)} {...a11yProps(0)} className={styles.tab}
                         component={Link} to={getPathForTab(TabTypes.STATISTICS)}/>
                    <Tab label={getLabelForTab(TabTypes.ATTENDANCE)} {...a11yProps(0)} className={styles.tab}
                         component={Link} to={getPathForTab(TabTypes.ATTENDANCE)}/>
                    <Tab label={getLabelForTab(TabTypes.TASKS)} {...a11yProps(1)} className={styles.tab}
                         component={Link} to={getPathForTab(TabTypes.TASKS)}/>
                    <Tab label={getLabelForTab(TabTypes.PLUGINS)} {...a11yProps(2)} className={styles.tab}
                         component={Link} to={getPathForTab(TabTypes.PLUGINS)}/>
                    {/*<Tab label="Vacations" {...a11yProps(2)} className={styles.tab}/>*/}
                    {/*<Tab label="Item Four" {...a11yProps(3)} className={styles.tab}/>*/}
                    {/*<Tab label="Item Five" {...a11yProps(4)} className={styles.tab}/>*/}
                    {/*<Tab label="Item Six" {...a11yProps(5)} className={styles.tab}/>*/}
                    {/*<Tab label="Item Seven" {...a11yProps(6)} className={styles.tab}/>*/}
                </Tabs>

                {/*<TabPanel value={value} index={0}>*/}

                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={1}>*/}
                {/*    /!*<TasksPanel/>*!/*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={2}>*/}
                {/*    Vacations*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={3}>*/}
                {/*    Item Four*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={4}>*/}
                {/*    Item Five*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={5}>*/}
                {/*    Item Six*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={6}>*/}
                {/*    Item Seven*/}
                {/*</TabPanel>*/}

                <Switch>
                    <Route path={getPathForTab(TabTypes.STATISTICS)} render={() => <OverviewPanel/>}/>
                    <Route path={getPathForTab(TabTypes.ATTENDANCE)} render={() => attendancePanel()}/>
                    <Route path={getPathForTab(TabTypes.TASKS)} render={() => <TasksPanel/>}/>
                    <Route path={getPathForTab(TabTypes.PLUGINS)} render={() => <PluginsPanel/>}/>
                    <Route path={getPathForTab(TabTypes.UNDEFINED)} render={() => <UnavailablePanel/>}/>
                </Switch>
            </Box>
        </BrowserRouter>
    );
}

interface OverviewTabsProps {
    onTabChanged: (type: TabTypes) => void
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const useStyles = makeStyles({
    root: {
        minHeight: `calc(100vh - 70px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    title: {
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,
    },
    tabSection: {
        width: '70%',
        height: '80vh'
    },
    tabsBackgroundBox: {

        backgroundColor: Colors.PRIMARY_BACKGROUND_COLOR,
        padding: Dimensions.SECTION_PADDING_TOP_HALF,
    },
    tab: {
        color: Colors.PRIMARY_TEXT_COLOR
    },
    tabContent: {},
    // paper: {
    //     // border: "1px solid black",
    //
    //     borderRadius: '50px',
    //
    //     backgroundColor: "black",
    //     height: '30vw',
    // },
});