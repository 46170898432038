import Dimensions from "../constants/Dimensions";
import {makeStyles} from "@material-ui/core";

export const useOverviewStyles = makeStyles({
    root: {
        padding: Dimensions.TAB_PANEL_PADDING
    },
    card: {
        height: '100%',
        width: '85%',
        marginTop: '20vw',
        marginBottom: '20vw'
    },
    title : {

        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,
    }
});