import {makeStyles} from "@material-ui/core";
import Dimensions from "../constants/Dimensions";
import Colors from "../constants/Colors";
import Constants from "../constants/Constants";
import RegistrationThankYou from "../login/RegistrationThankYou";
import React from "react";


export default function Settings() {

    // TODO : load settings

    const styles = useStyles()

    if (Constants.USING_PROD_VERSION) {
        return (
            <RegistrationThankYou/>
        )
    }

    return (
        <div className={styles.root}>

            <h1 className={styles.settingsTitle}>Settings</h1>

        </div>
    )
}

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        // width: '100%',
        paddingTop: Dimensions.SECTION_PADDING_TOP,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '70%',
    },
    settingsTitle: {
        // textAlign: "left",
        // marginLeft: '15%',
        marginBottom: 0,
        marginRight: 0,
        fontSize: Dimensions.PAGE_TITLE_TEXT_SIZE,

        // width: '70%',
    },
});