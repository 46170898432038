import {useState} from "react";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Colors from "../constants/Colors";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DialogProps} from "@mui/material/Dialog/Dialog";
import {FormControlLabel, FormGroup} from "@mui/material";
import {dialogPaperProps} from "../ui/MUIStyles";
import {makeStyles} from "@material-ui/core";
import CustomSwitch from "../ui/CustomSwitch";

export default function UserTypeDialog(props: UserTypeDialogProps) {

    const styles = useStyles()

    // Separate custom props from DialogProps
    const {isEmployer, handleCloseDialog, handleDialogResult, ...rest} = props
    const [employer, setEmployer] = useState(isEmployer)

    const handleClose = () => {
        props.handleCloseDialog()
    }

    const handleContinue = () => {

        // Return result
        props.handleDialogResult(employer)
    }

    const handleEmployerSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmployer(e.target.checked)
    }

    const userTypeSpan = (<span
        style={{color: Colors.PRIMARY_TEXT_BLUE, fontWeight: 'bolder'}}>{employer ? 'employer' : 'employee'}</span>)

    return (
        <div>
            <Dialog {...rest}
                    onClose={handleClose}
                    sx={{}}
                    PaperProps={dialogPaperProps}
            >
                <DialogTitle
                    sx={{
                        color: Colors.PRIMARY_TEXT_COLOR,
                    }}
                >You're signing up as {userTypeSpan}</DialogTitle>

                <DialogContent style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>

                    <FormGroup>
                        <FormControlLabel
                            control={<CustomSwitch value={employer}
                                             defaultChecked={isEmployer}
                                             onChange={handleEmployerSwitchChange}
                                             // className={styles.switch}
                                             // classes={{
                                             //     track: styles.switch_track,
                                             //     switchBase: styles.switch_base,
                                             // }}
                                             // color="primary"
                            />}
                            label="Employer"
                            labelPlacement={'start'}
                            style={{
                                color: Colors.PRIMARY_TEXT_COLOR,
                            }}
                        />
                    </FormGroup>

                </DialogContent>
                <DialogActions style={{
                    margin: 0,
                    padding: 0,
                }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleContinue}>Continue</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

interface UserTypeDialogProps extends DialogProps {
    isEmployer: boolean,
    handleCloseDialog: () => void,
    handleDialogResult: (isEmployer: boolean) => void,
}

const useStyles = makeStyles({

})