import {makeStyles} from "@material-ui/core";
import Colors from "../constants/Colors";

export const NAVIGATION_HEIGHT = '70px'

export const navigationStyles = makeStyles(theme => ({
    navigation: {
        width: '100%',
        height: NAVIGATION_HEIGHT, // 3.5vw 75px 4.5rem
        background: 'black',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',

        // Keep attached to top
        position: 'fixed',
        top: 0,
    },
    titleItem: {
        fontWeight: 'bold',
        color: 'white',
        textDecoration: 'none',
        wordBreak: 'keep-all',
        overflow: 'auto',
        textAlign: 'center',
        flexGrow: 1
    },
    title: {
        fontSize: '25px', // 2.5vmin
        textDecoration: 'none',
    },
    navItemsStack: {
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        flexGrow: 4,

        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    navItem: {
        padding: '20px',
        textDecoration: 'none',
    },
    navItemText: {
        fontSize: '16px', // 1.5vmin
        fontWeight: 'bold',
        color: 'lightgray',
        wordBreak: 'keep-all',
        overflow: 'auto',

        '&:hover': {
            color: Colors.PRIMARY_TEXT_BLUE
        },
    },
    loginStackContent: {
        flexGrow: 1,

        // Prevent changing position of items when switching navigation
        // maxWidth: '280px',
    },
    loginStackDesktop: {

        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    loginItem: {
        textDecoration: 'none',
    },
    loginButton: {

        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },

        "&:hover": {
            // Override IconButton's hover color
            "& .MuiSvgIcon-root": {
                color: Colors.PRIMARY_TEXT_BLUE,
            }
        },
    },
    loginIcon: {
        color: "white",
    },
    menuIconButton: {
        "&:hover": {
            // Override IconButton's hover color
            "& .MuiSvgIcon-root": {
                color: Colors.PRIMARY_TEXT_BLUE,
            }
        },
    },
    menuIcon: {
        color: "white",
    },
    mobileMenuIconButton: {

        display: 'none !important',

        [theme.breakpoints.down('sm')]: {
            display: 'inline !important',
        },
    },
    mobileMenuIcon: {
        color: "white",
    },
}));