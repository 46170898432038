import {makeStyles} from "@material-ui/core";
import Dimensions from "../../constants/Dimensions";
import {useOverviewStyles} from "../OverviewStyles";


export default function PluginsPanel() {

    const styles = useStyles()
    const commonStyles = useOverviewStyles()

    return (
        <div className={styles.root}>

            <h1>Plugins</h1>

        </div>
    )
}

const useStyles = makeStyles(theme => ({

    root : {
        padding: Dimensions.TAB_PANEL_PADDING
    },
    title : {
    }


}))

