import {userPresenceReference} from "./References";
import {UserPresence} from "./Models";


export function setUserPresence(userId : string, userPresence : UserPresence, callback : (success : boolean) => void) {
    const userPresenceRef = userPresenceReference(userId)
    userPresenceRef.set(userPresence).then(() => {
        // console.log("User presence for user " + userId + " set successfully (data : " + JSON.stringify(userPresence) + ")")
        callback(true)
    }).catch((error) => {
        console.log("Failed to update user presence")
        callback(false)
    })
}

