import Colors from "../constants/Colors";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {useContext, useState} from "react";
import {AppDataContext} from "../AppDataContext";
import {Redirect} from "react-router-dom";
import NavigationPaths from "../constants/NavigationPaths";
import {DialogProps} from "@mui/material/Dialog/Dialog";
import Dimensions from "../constants/Dimensions";
import {dialogPaperProps} from "../ui/MUIStyles";


export default function LogOutDialog(props: LogOutDialogProps) {

    const appData = useContext(AppDataContext)

    // const [logOutDialogOpen, setLogOutDialogOpen] = React.useState(false);

    // const openLogOutDialog = () => {
    //     setLogOutDialogOpen(true);
    // };
    const closeLogOutDialog = () => {
        props.handleCloseDialog()
        // setLogOutDialogOpen(false);
    };

    const [loggedIn, setLoggedIn] = useState(true)
    if (!loggedIn) {
        return <Redirect to={NavigationPaths.HOME}/>
    }

    const logOut = () => {

        if (appData.firebaseApp == null) {
            return
        }

        appData.firebaseApp.auth().signOut().then(function () {
            console.log("Log out successful")

            closeLogOutDialog()
            setLoggedIn(false)

        }).catch(function (error) {
            console.log(error)
        });

        // Open log out dialog
    };

    const {handleCloseDialog, ...rest} = props

    return (
        <Dialog
            {...rest}
            onClose={closeLogOutDialog}
            PaperProps={dialogPaperProps}
        >
            <DialogTitle
                sx={{
                    color: Colors.PRIMARY_TEXT_COLOR,
                }}>
                {"Are you sure you want to log out?"}
            </DialogTitle>

            <DialogActions>
                <Button onClick={closeLogOutDialog} autoFocus>
                    Cancel
                </Button>
                <Button onClick={logOut}>Log out</Button>
            </DialogActions>
        </Dialog>
    )
}

interface LogOutDialogProps extends DialogProps {
    handleCloseDialog: () => void,
}

