import {Alert, Button, Checkbox, Collapse, FormControlLabel, FormGroup, IconButton, Stack} from "@mui/material";
// import { FacebookAuthProvider } from "firebase/auth";
// import firebase from "firebase/app";
// import EmailAuthProvider = firebase.auth.EmailAuthProvider;
// import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
// import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import React, {useContext, useState} from "react";
import {AppDataContext} from "../AppDataContext";
import NavigationPaths from "../constants/NavigationPaths";
import {authButtonStyle, facebookButtonStyle, useLoginStyles} from "./LoginStyles";
import LoginTextField from "./LoginTextField";
import {isEmailValid, isNameValid, isPasswordValid} from "./AuthValidation";
import {Box, makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {
    convertToUserSerializable,
    getBlurredAttribute,
    getChangedAttribute,
    initialSignUpState,
    SignUpType
} from "./SignUpState";
import {LoginConstants} from "./LoginConstants";
import Colors from "../constants/Colors";
import EmployeeDialog from "./EmployeeDialog";
import EmployerDialog from "./EmployerDialog";
import {CreateEmployerRequestData} from "../../functions/src/RequestData";
import {createEmployee, createEmployer} from "../firebase/functions/CloudFunctions";
import {Redirect} from "react-router-dom";
import firebase from "firebase";
import {CreateEmployeeRequestData} from "../firebase/functions/RequestData";
import UserTypeDialog from "./UserTypeDialog";
import {getUser} from "../firebase/firestore/Listeners";
import {splitFullName} from "../utils/NameUtil";
import {User, UserSerializable} from "../firebase/firestore/Models";
import CloseIcon from "@mui/icons-material/Close";
import FacebookUnavailableAlert from "./FacebookUnavailableAlert";
import Constants from "../constants/Constants";
import RegistrationThankYou from "./RegistrationThankYou";
import Dimensions from "../constants/Dimensions";
// import firebase from "firebase";
// import UserCredential = firebase.auth.UserCredential;

// https://firebase.google.com/docs/auth/web/password-auth
// https://firebase.google.com/docs/auth/web/facebook-login
// https://github.com/nellsam/crypto_wallet/blob/master/src/authentication/ui/AuthDialog.tsx
// https://medium.com/geekculture/firebase-auth-with-react-and-typescript-abeebcd7940a
// https://mui.com/components/text-fields/
// https://mui.com/components/paper/

export default function SignUp() {

    const styles = useStyles()
    const commonStyles = useLoginStyles()

    const SELECTABLE_COUNT = 4

    const appData = useContext(AppDataContext)

    const functions = appData.firebaseApp!!.functions()
    const auth = appData.firebaseApp!!.auth()

    const app = appData.firebaseApp
    if (auth == null) {
        console.log("App is null")
    }

    // Hooks
    const [signUpState, setSignUpState] = useState(initialSignUpState)
    const [selectedField, setSelectedField] = useState(1)
    const [employerDialogOpened, setEmployerDialogOpened] = React.useState(false);
    const [employeeDialogOpened, setEmployeeDialogOpened] = React.useState(false);
    const [facebookAlertOpen, setFacebookAlertOpen] = useState(false)
    const [userTypeDialogOpened, setUserTypeDialogOpened] = React.useState(false);
    const [loggedIn, setLoggedIn] = useState(appData.firebaseApp?.auth().currentUser != null)

    // References
    const firstNameRef = React.createRef<HTMLInputElement>();
    const lastNameRef = React.createRef<HTMLInputElement>();
    const emailRef = React.createRef<HTMLInputElement>();
    const passwordRef = React.createRef<HTMLInputElement>();
    const companyRef = React.createRef<HTMLInputElement>();
    // const employerSignUpRef = React.createRef<HTMLButtonElement>();
    // const privacyPolicyRef = React.createRef<HTMLButtonElement>();

    let invalidFirstName = false
    let invalidLastName = false
    let invalidEmail = false
    let invalidPassword = false
    let invalidPrivacyPolicy = false

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const redirectOverview = (
        <Redirect to={NavigationPaths.OVERVIEW}/>
    )

    if (loggedIn) {
        console.log("Redirecting to overview")
        return redirectOverview
    }

    const handleSignUpClick = () => {

        // Update state to revalidate all elements
        setSignUpState(prevState => ({

            // Restore previous state
            ...prevState,

            formSubmitted: true,

            signUpType: SignUpType.EMAIL,
        }))

        const firstName = signUpState.firstName
        const lastName = signUpState.lastName
        const email = signUpState.email
        const password = signUpState.password
        const employerSignUp = signUpState.employerSignUp
        const company = signUpState.company
        const agreePrivacyPolicy = signUpState.agreePrivacyPolicyAndTermsOfUse

        if (!isNameValid(firstName)) return
        if (!isNameValid(lastName)) return
        if (!isEmailValid(email)) return
        if (!isPasswordValid(password)) return
        if (!agreePrivacyPolicy) return;

        if (!Constants.USING_PROD_VERSION) {
            console.log("Signing up with " + JSON.stringify(signUpState))
        }

        if (employerSignUp) {
            setEmployerDialogOpened(true)
        } else {
            setEmployeeDialogOpened(true)
        }
    }

    const handleFacebookSignUpClick = () => {

        if (Constants.USING_PROD_VERSION) {
            // TODO : Temporary
            setFacebookAlertOpen(true)
            return
        }

        if (signUpState.signUpType !== SignUpType.EMAIL) {
            setSignUpState(prevState => ({

                // Restore previous state
                ...prevState,

                // Update sign up type
                signUpType: SignUpType.FACEBOOK,
            }))
        }

        // Determine user type before continuing in authentication
        setUserTypeDialogOpened(true)
    }

    // Handle form changes
    const handleTextFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

        // Id and value of changed field
        const {id, value} = e.target

        const changedField: string = getChangedAttribute(id.toString());

        // console.log("Changed field id:" + id.toString() + ", changed field: " + changedField + ", value: " + value)

        // Update state of react component
        setSignUpState(prevState => ({

            // Restore previous state
            ...prevState,

            // Update value of changed field (var name = field id)
            [id]: value,

            // Update state of changed field
            [changedField]: true
        }))
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        // Id and value of changed field
        const {id, value} = e.target

        const changedField: string = getChangedAttribute(id.toString());

        // console.log("Changed field id:" + id.toString() + ", changed field: " + changedField + ", value: " + e.target.checked)

        // Update state of react component
        setSignUpState(prevState => ({

            // Restore previous state
            ...prevState,

            // Update value of changed field (var name = field id)
            [id]: e.target.checked,

            // Update state of changed field
            [changedField]: true
        }))
    }


    const handleTextFieldBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        // Id and value of changed field
        const {id, value} = e.target

        const blurredField: string = getBlurredAttribute(id.toString())

        // console.log("Blurred field id:" + id.toString() + ", blurred field: " + blurredField)

        // Update state of react component
        setSignUpState(prevState => ({

            // Restore previous state
            ...prevState,

            // Update value of changed field (var name = field id)
            [id]: value,

            // Update state of changed field
            [blurredField]: true
        }))
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        const {id, value} = e.currentTarget
        const key = e.key

        let selectField = selectedField;

        if (key === 'ArrowDown') {
            if (selectField < SELECTABLE_COUNT) {
                selectField = selectField + 1
            }

        } else if (key === 'ArrowUp') {
            if (selectField > 1) {
                selectField = selectField - 1
            }
        } else if (key === 'Enter') {
            if (selectField < SELECTABLE_COUNT) {
                selectField = selectField + 1
            }
        }

        if (selectField !== selectedField) {

            // console.log("Selecting " + selectField)

            switch (selectField) {
                case 1 : {

                    // TODO : Move cursor to the end of text
                    // const element = e.currentTarget
                    // setTimeout(function () {
                    //
                    //     // https://stackoverflow.com/a/10576409/12580605
                    //     if (element instanceof HTMLInputElement) {
                    //         element.selectionStart = element.selectionEnd = 10000;
                    //     }
                    // }, 0);

                    firstNameRef.current?.focus()
                    // firstNameRef.current?.select()

                    break;
                }
                case 2 : {
                    lastNameRef.current?.focus()
                    // lastNameRef.current?.select()
                    break;
                }
                case 3 : {
                    // @ts-ignore
                    // document.getElementById(LoginConstants.EMAIL_ID).focus()
                    emailRef.current?.focus()
                    // emailRef.current?.select()
                    break;
                }
                case 4 : {
                    passwordRef.current?.focus()
                    // passwordRef.current?.select()
                    break;
                }
            }

            setSelectedField(selectField)
        }
    }

    const handleTextFieldSelect = (e: React.ChangeEvent<HTMLInputElement>) => {

        const {id, value} = e.target

        switch (id) {
            case LoginConstants.FIRST_NAME_ID : {
                setSelectedField(1)
                break;
            }
            case LoginConstants.LAST_NAME_ID : {
                setSelectedField(2)
                break;
            }
            case LoginConstants.EMAIL_ID : {
                setSelectedField(3)
                break;
            }
            case LoginConstants.PASSWORD_ID : {
                setSelectedField(4)
                break;
            }
        }

        // console.log("Selected " + id.toString())
    }

    const handleCheckboxSelect = (e: React.ChangeEvent<HTMLButtonElement>) => {

        const {id, value} = e.target

        // switch (id) {
        //     case LoginConstants.EMPLOYER_SIGN_UP_ID : {
        //         setSelectedField(5)
        //         break;
        //     }
        //     case LoginConstants.PRIVACY_POLICY_ID : {
        //         setSelectedField(6)
        //         break;
        //     }
        // }
    }

    const handleCloseEmployeeDialog = () => {
        setEmployeeDialogOpened(false)
    }

    const handleCloseEmployerDialog = () => {
        setEmployerDialogOpened(false)
    }

    const handleCloseUserTypeDialog = () => {
        setUserTypeDialogOpened(false)
    }

    const handleFacebookAlertClose = () => {
        setFacebookAlertOpen(false)
    }

    const handleUserTypeDialogResult = (isEmployer: boolean) => {

        setUserTypeDialogOpened(false)

        if (signUpState.employerSignUp !== isEmployer) {
            setSignUpState(prevState => ({

                // Restore previous state
                ...prevState,

                employerSignUp: isEmployer
            }))
        }

        if (isEmployer) {
            console.log("Opening employer dialog")
            setEmployerDialogOpened(true)
        } else {
            console.log("Opening employee dialog")
            setEmployeeDialogOpened(true)
        }
    }

    const handleEmployerDialogResult = (inviteCode: string) => {

        setEmployerDialogOpened(false)

        console.log("Invite code " + inviteCode + " returned from dialog")

        if (auth == null) {
            console.log("Auth is null")
            return
        }

        if (signUpState.signUpType === SignUpType.EMAIL) {

            auth.createUserWithEmailAndPassword(signUpState.email, signUpState.password)
                .then((userCredential) => {

                    const authUser = userCredential.user
                    console.log("User id " + authUser?.uid)
                    console.log("Context user " + appData.firebaseUser?.uid)

                    const companyName = signUpState.company != null ? signUpState.company : ""

                    const user = convertToUserSerializable(signUpState)
                    const requestData: CreateEmployerRequestData = {
                        user: user,
                        companyName: companyName,
                        inviteCode: inviteCode,
                    }

                    createEmployer(requestData, (success => {
                        console.log("Employer created successfully")

                        if (success) {
                            return redirectOverview
                            // return (
                            //     <Redirect to={NavigationPaths.OVERVIEW}/>
                            // )
                        }
                    }))
                })
                .catch((error) => {
                    console.log("Failed to authenticate user: " + JSON.stringify(error))
                })
        } else if (signUpState.signUpType === SignUpType.FACEBOOK) {

            // https://firebase.google.com/docs/auth/web/facebook-login
            // https://medium.com/litslink/firebase-facebook-sign-in-method-20571606c889

            // fbProvider.setCustomParameters({
            //     'display': 'popup'
            // });

            const fbProvider = new firebase.auth.FacebookAuthProvider();

            fbProvider.addScope('email');
            fbProvider.addScope('public_profile')

            auth
                .signInWithPopup(fbProvider)
                // .getRedirectResult()
                .then((result) => {

                    // The signed-in user info.
                    const authUser = result.user;
                    const userId = authUser?.uid

                    console.log("User id " + authUser?.uid)
                    console.log("Context user " + appData.firebaseUser?.uid)

                    if (userId == null || userId.length === 0) {
                        console.log("Facebook - user id is null")
                        return
                    }

                    // Check if user exists
                    getUser(userId, (firebaseUser) => {
                        if (firebaseUser != null) {
                            console.log("User already exists")
                            setLoggedIn(true)
                            return
                        }

                        const fullName = authUser?.displayName
                        const email = authUser?.email

                        console.log("Retrieved email " + email)

                        if (email == null || typeof (email) !== 'undefined') {
                            console.log("Email from facebook provider is null")
                            return;
                        }

                        let firstName = ''
                        let lastName = ''
                        if (fullName != null) {
                            const splitName = splitFullName(fullName)
                            firstName = splitName.firstName
                            lastName = splitName.lastName
                        }
                        const isEmployer = signUpState.employerSignUp
                        const companyName = signUpState.company != null ? signUpState.company : ""

                        const user: UserSerializable = ({
                            firstName: firstName,
                            lastName: lastName,
                            email: email,
                            phoneNumber: '',
                            address: '',
                            employer: isEmployer,
                            iconId: '',
                            workLoad: '',
                            groupIdPreference: '',
                            companyIdPreference: '',
                            nightModePreference: true,
                        })
                        const requestData: CreateEmployerRequestData = {
                            user: user,
                            companyName: companyName,
                            inviteCode: inviteCode,
                        }

                        createEmployer(requestData, (success => {
                            console.log("Employer created successfully")

                            if (success) {
                                return redirectOverview
                                // return (
                                //     <Redirect to={NavigationPaths.OVERVIEW}/>
                                // )
                            }
                        }))
                    })


                    // const credential = result.credential;
                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    // const accessToken = credential.accessToken;

                    // ...
                })
                .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    const credential = error.credential;

                    console.log("Failed to authenticate user with facebook")
                });

        }
    }

    const handleEmployeeDialogResult = (groupId: string) => {

        setEmployeeDialogOpened(false)

        console.log("Group id " + groupId + " returned from dialog")

        if (auth == null) {
            console.log("Auth is null")
            return
        }

        if (signUpState.signUpType === SignUpType.EMAIL) {

            auth.createUserWithEmailAndPassword(signUpState.email, signUpState.password)
                .then((userCredential) => {

                    const authUser = userCredential.user
                    console.log("User id " + authUser?.uid)
                    console.log("Context user " + appData.firebaseUser?.uid)

                    const user = convertToUserSerializable(signUpState)
                    const requestData: CreateEmployeeRequestData = ({
                        groupId: groupId,
                        user: user
                    })

                    createEmployee(requestData, (success => {
                        console.log("Employee created successfully")
                        if (success) {
                            return redirectOverview
                            // return (
                            //     <Redirect to={NavigationPaths.OVERVIEW}/>
                            // )
                        }
                    }))

                })
                .catch((error) => {
                    console.log("Failed to authenticate user: " + JSON.stringify(error))
                })
        } else if (signUpState.signUpType === SignUpType.FACEBOOK) {


            const fbProvider = new firebase.auth.FacebookAuthProvider();

            // tODO
        }
    }

    // Validate form once changes are made or form is submitted
    if ((signUpState.firstNameBlurred && signUpState.firstNameChanged) || signUpState.formSubmitted) {
        invalidFirstName = !isNameValid(signUpState.firstName)
    }
    if ((signUpState.lastNameBlurred && signUpState.lastNameChanged) || signUpState.formSubmitted) {
        invalidLastName = !isNameValid(signUpState.lastName)
    }
    if ((signUpState.emailBlurred && signUpState.emailChanged) || signUpState.formSubmitted) {
        invalidEmail = !isEmailValid(signUpState.email)
    }
    if ((signUpState.passwordBlurred && signUpState.passwordChanged) || signUpState.formSubmitted) {
        invalidPassword = !isPasswordValid(signUpState.password)
    }
    if (signUpState.formSubmitted) {
        invalidPrivacyPolicy = !signUpState.agreePrivacyPolicyAndTermsOfUse
    }

    const employerSignUpLabel = (
        <span className={styles.checkboxLabel}>
            Sign up as <span style={{fontWeight: 600}}>employer</span>
        </span>
    )

    const privacyPolicyLabel = (
        <span className={styles.checkboxLabel}>
            Agree with <a href={NavigationPaths.PRIVACY_POLICY}
                          target="_blank"
                          className={styles.checkboxLink} rel="noreferrer">privacy policy</a> and <a
            href={NavigationPaths.TERMS_OF_USE}
            target="_blank"
            className={styles.checkboxLink} rel="noreferrer">terms of use</a>
        </span>
    )

    return (
        <div className={commonStyles.root}>

            <FacebookUnavailableAlert handleAlertClose={handleFacebookAlertClose} alertOpened={facebookAlertOpen}/>

            <div className={commonStyles.content}>

                <Typography variant={"h1"} className={commonStyles.loginTitle}>Sign up</Typography>

                {/*<Box elevation={3} variant={"outlined"} className={commonStyles.textFieldPaper}>*/}
                <Box component="span"
                     sx={{p: 2, borderRadius: '50px',}}
                     className={commonStyles.textFieldBox}
                     onKeyDown={handleKeyDown}>

                    <Stack spacing={3} direction={"column"} className={commonStyles.textFieldStack}>

                        <LoginTextField
                            id={LoginConstants.FIRST_NAME_ID}
                            label="First name"
                            type="text"
                            error={invalidFirstName}
                            value={signUpState.firstName}
                            helperText={invalidFirstName ? LoginConstants.INVALID_FIRST_NAME : ''}
                            onSelect={handleTextFieldSelect}
                            onChange={handleTextFieldChange}
                            onBlur={handleTextFieldBlur}
                            inputRef={firstNameRef}
                            autoFocus={selectedField === 1}
                        />

                        <LoginTextField
                            id={LoginConstants.LAST_NAME_ID}
                            label="Last name"
                            type="text"
                            error={invalidLastName}
                            value={signUpState.lastName}
                            helperText={invalidLastName ? LoginConstants.INVALID_LAST_NAME : ''}
                            onSelect={handleTextFieldSelect}
                            onChange={handleTextFieldChange}
                            onBlur={handleTextFieldBlur}
                            inputRef={lastNameRef}
                        />

                        <LoginTextField
                            id={LoginConstants.EMAIL_ID}
                            label="Email"
                            type="email"
                            error={invalidEmail}
                            value={signUpState.email}
                            helperText={invalidEmail ? LoginConstants.INVALID_EMAIL : ''}
                            onSelect={handleTextFieldSelect}
                            onChange={handleTextFieldChange}
                            onBlur={handleTextFieldBlur}
                            inputRef={emailRef}
                        />

                        <LoginTextField
                            id={LoginConstants.PASSWORD_ID}
                            label="Password"
                            type="password"
                            error={invalidPassword}
                            value={signUpState.password}
                            helperText={invalidPassword ? LoginConstants.INVALID_PASSWORD : ''}
                            onSelect={handleTextFieldSelect}
                            onChange={handleTextFieldChange}
                            onBlur={handleTextFieldBlur}
                            inputRef={passwordRef}
                        />

                    </Stack>

                    <FormGroup className={styles.checkboxGroup}>

                        <FormControlLabel
                            label={employerSignUpLabel}
                            className={commonStyles.checkbox}
                            control={
                                <Checkbox checked={signUpState.employerSignUp}
                                          onChange={handleCheckboxChange}
                                          id={LoginConstants.EMPLOYER_SIGN_UP_ID}
                                          onSelect={handleCheckboxSelect}
                                          sx={{
                                              color: "darkgrey",
                                              '&.Mui-checked': {
                                                  color: Colors.PRIMARY_TEXT_BLUE,
                                              },
                                          }}
                                />
                            }
                        />

                        {!signUpState.employerSignUp ? null : (

                            <LoginTextField
                                id={LoginConstants.COMPANY_ID}
                                label="Company (optional)"
                                type="text"
                                inputRef={companyRef}
                                onChange={handleTextFieldChange}
                                // display: companyVisibility, // signUpState.employerSignUp ? 'visible' : 'none'
                                style={{
                                    marginBottom: '10px',
                                }}
                            />
                        )}

                        <FormControlLabel
                            label={privacyPolicyLabel}
                            className={commonStyles.checkbox}
                            control={
                                <Checkbox checked={signUpState.agreePrivacyPolicyAndTermsOfUse}
                                          onChange={handleCheckboxChange}
                                          id={LoginConstants.PRIVACY_POLICY_ID}
                                          onSelect={handleCheckboxSelect}
                                          sx={{
                                              color: invalidPrivacyPolicy ? "red" : "darkgrey",
                                              '&.Mui-checked': {
                                                  color: Colors.PRIMARY_TEXT_BLUE,
                                              },
                                          }}
                                />
                            }
                        />
                    </FormGroup>

                    <Stack spacing={2}
                           direction="column"
                           className={styles.authButtonStack}>
                        <Button variant="contained"
                                onClick={handleSignUpClick}
                                className={commonStyles.signUpButton}
                                sx={authButtonStyle}
                                color="primary">CREATE
                            NEW
                            ACCOUNT</Button>
                        <Button variant="contained"
                                onClick={handleFacebookSignUpClick}
                                className={commonStyles.facebookButton}
                                sx={facebookButtonStyle}
                                color="primary"
                        >SIGN
                            UP WITH FACEBOOK</Button>
                    </Stack>

                    <a className={commonStyles.authRedirectItem} href={NavigationPaths.SIGN_IN}>
                        <Typography variant={'h5'} className={commonStyles.authRedirectText}>Have an account?
                            <span style={{fontWeight: 600}}> Sign in</span></Typography>
                    </a>

                </Box>

                <EmployerDialog
                    open={employerDialogOpened}
                    handleCloseDialog={handleCloseEmployerDialog}
                    handleDialogResult={handleEmployerDialogResult}
                />

                <EmployeeDialog
                    open={employeeDialogOpened}
                    handleCloseDialog={handleCloseEmployeeDialog}
                    handleDialogResult={handleEmployeeDialogResult}
                    email={signUpState.email}
                />

                <UserTypeDialog
                    open={userTypeDialogOpened}
                    handleCloseDialog={handleCloseUserTypeDialog}
                    handleDialogResult={handleUserTypeDialogResult}
                    isEmployer={signUpState.employerSignUp}/>

            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    checkboxGroup: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '25px',
    },
    checkboxLabel: {
        fontSize: Dimensions.INFO_TITLE_TEXT_SIZE_MD,

        [theme.breakpoints.down('sm')]: {
            fontSize: Dimensions.REDIRECT_SMALL_TEXT_SIZE,
        },
    },
    checkboxLink: {
        color: Colors.SECONDARY_TEXT_COLOR,
        fontWeight: 600,
        textDecoration: 'none',
        '&:hover': {
            color: Colors.PRIMARY_TEXT_BLUE,
        },
    },
    authButtonStack: {
        marginTop: '25px',
    },
}));
